import React, { createRef } from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { routingActions } from "../redux/_actions";
import { authActions } from "../redux/_actions";
import { postingActions } from "../redux/_actions";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Box from "@material-ui/core/Box";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Switch from "@material-ui/core/Switch";
import { withRouter } from "next/router";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";

const REACT_APP_SITE_KEY = process.env.NEXT_PUBLIC_reCAPTCHA_KEY;

const styles = (theme) => ({
  buttonsRoot: {
    "& > *": {
      margin: theme.spacing(1),
    },
    marginTop: "20px",
  },
  postPagePaper: {
    padding: "5%",
    textAlign: "center",
    boxShadow: "0px 0px 25px 5px rgba(0,0,0,0.2)",
    minHeight: "500px",
    display: "flex",
    alignItems: "center",
    margin: "0px 5%",
  },
  subPaper: {
    width: "100%",
  },
  navButton: {
    color: "white",
  },
  navButton1: {
    color: theme.palette.secondary.main,
    marginRight: "20px",
  },
  listingTypeHeading: {
    marginTop: "0px !important",
    color: theme.palette.primary.main,
  },
  howItWorksContainer: {
    width: "90%",
    height: "100%",
    margin: "auto",
    maxWidth: "1284px",
  },
  sectionHeading4: {
    width: "100%",
    textAlign: "left",
    paddingLeft: "2%",
    color: theme.palette.primary.main,
    fontSize: "34px",
    fontWeight: "bold",
    lineHeight: 1.25,
  },
  checkIcon: {
    color: "rgb(4, 175, 136)",
    marginRight: "10px",
  },
  points: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    margin: "1.5rem",
  },
  howItWorksImage: {
    display: "flex",
    alignItems: "center",
    "& img": {
      [theme.breakpoints.up("md")]: {
        marginLeft: "auto",
      },
      [theme.breakpoints.down("md")]: {
        margin: "auto",
      },
    },
  },
  buttonhowitworks: {
    textAlign: "left",
    alignItems: "left",
    margin: "1.6rem",
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxWidth: "300px",
    [theme.breakpoints.down(400)]: {
      maxWidth: "220px",
    },
  },
  signUpModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  signUpModalPaper: {
    backgroundColor: "white !important",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
  switchGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeMap: {
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  agreeText: {
    fontSize: "10px",
    "& a": {
      color: "black",
    },
  },
  recaptchaWrapper: {
    padding: "10px 0",
    "& div": {
      margin: "auto",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} className="noMargin1">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    right: "0px",
    "& > span": {
      maxWidth: 70,
      width: "100%",
      backgroundColor: "rgb(4, 175, 136)",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsValue: 0,
      googleResponse: {},
      signUpName: "",
      signUpPhoneNumber: "",
      signUpPassword: "",
      signInPhoneNumber: "",
      signInPassword: "",
      showSignUpPassword: false,
      showSignInPassword: false,
      signUpModalOpen: false,
      signUpOTP: "",
      signInModalOpen: false,
      signInOTP: "",
      signUpEmail: "",
      isLoginPassword: true,
      signUpRecaptchaToken: null,
      signupResendRecaptchaToken: null,
      loginRecaptachaToken: null,
      loginResendRecaptchaToken: null,
    };
    this.loginRecaptchaRef = createRef();
    this.loginResendRecaptchaRef = createRef();
    this.signupRecaptcharRef = createRef();
    this.signupResendRecaptchaRef = createRef();
  }
  successGoogle = (response) => {
    this.setState({ googleResponse: response });
    this.props.setCurrentStep(8);
  };

  handleRouting = (route) => {
    if (route === "previous") {
      this.props.setCurrentStep(6);
    }
  };

  handleTabs = (event, newValue) => {
    this.setState({ tabsValue: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ tabsValue: index });
  };

  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowSignUpPassword = () => {
    this.setState({ showSignUpPassword: !this.state.showSignUpPassword });
  };

  handleMouseDownSignUpPassword = (event) => {
    event.preventDefault();
  };

  handleClickShowSignInPassword = () => {
    this.setState({ showSignInPassword: !this.state.showSignInPassword });
  };

  handleMouseDownSignInPassword = (event) => {
    event.preventDefault();
  };

  handleSignUpModalOpen = () => {
    if (!this.state.signUpRecaptchaToken) {
      alert("Please verify the reCAPTCHA!");
    } else {
      this.props.signUp(
        this.state.signUpName,
        this.state.signUpPhoneNumber,
        this.state.signUpPassword,
        this.state.signUpRecaptchaToken
      );
      this.signupRecaptcharRef.current.reset();
      this.setState({ signUpModalOpen: true, signUpRecaptchaToken: null });
    }
  };

  onSignupRecaptchaChange = (token) => {
    this.setState({ signUpRecaptchaToken: token });
  };

  onSignupResendRecaptchaChange = (token) => {
    this.setState({ signupResendRecaptchaToken: token });
  };

  onLoginRecaptchaChange = (token) => {
    this.setState({ loginRecaptachaToken: token });
  };

  onLoginResendRecaptchaChange = (token) => {
    this.setState({ loginResendRecaptchaToken: token });
  };

  handleSignUpModalClose = () => {
    this.setState({ signUpModalOpen: false });
  };
  handleSignInModalOpen = () => {
    if (!this.state.loginRecaptachaToken) {
      alert("Please verify the reCAPTCHA!");
    } else {
      console.log("recaptcha token", this.state.loginRecaptachaToken);
      this.props.requestOTP(
        this.state.signInPhoneNumber,
        this.state.loginRecaptachaToken
      );
      this.loginRecaptchaRef.current.reset();
      this.setState({ signInModalOpen: true, loginRecaptachaToken: null });
    }
  };
  handleSignInModalClose = () => {
    this.setState({ signInModalOpen: false });
  };

  handleSignUp = () => {
    this.props.verifyOTP(this.state.signUpPhoneNumber, this.state.signUpOTP);
  };

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.verifyOTPSuccess, this.props.jT);
    if (nextProps.verifyOTPSuccess || nextProps.passwordSignInSuccess) {
      this.props.setCurrentStep(8);
    }
    if (nextProps.passwordSignInFailureReason === "OTP not verified") {
      this.setState({ signInModalOpen: true });
    }
  }

  handlePasswordSignIn = () => {
    if (!this.state.loginRecaptachaToken) {
      alert("Please verify the reCAPTCHA!");
    } else {
      this.props.passwordSignIn(
        this.state.signInPhoneNumber,
        this.state.signInPassword,
        this.state.loginRecaptachaToken
      );
      this.loginRecaptchaRef.current.reset();
      this.setState({ loginRecaptachaToken: null });
    }
  };

  handleOTPSignIn = () => {
    this.props.verifyOTP(this.state.signInPhoneNumber, this.state.signInOTP);
  };

  handleSwitch = () => {
    this.setState({ isLoginPassword: !this.state.isLoginPassword });
  };

  handleResendOtp = (event, type) => {
    if (type === "signUp") {
      if (!this.state.signupResendRecaptchaToken) {
        alert("Please verify the reCAPTCHA!");
      } else {
        this.props.requestOTP(
          this.state.signUpPhoneNumber,
          this.state.signupResendRecaptchaToken
        );
        this.signupResendRecaptchaRef.current.reset();
        this.setState({ signupResendRecaptchaToken: null });
      }
    } else {
      if (!this.state.loginResendRecaptchaToken) {
        alert("Please verify the reCAPTCHA!");
      } else {
        this.props.requestOTP(
          this.state.signInPhoneNumber,
          this.state.loginResendRecaptchaToken
        );
        this.loginResendRecaptchaRef.current.reset();
        this.setState({ loginResendRecaptchaToken: null });
      }
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.subPaper}>
        <Helmet>
          <title>Login/Signup for your Account | FindMyRoom</title>
          <meta
            name="description"
            content="Need a Flatmate/Roommate for your Apartment? Rent you Room, House/Apartment or Flat Now. Post Ad for FREE."
          />
        </Helmet>
        <div>
          <StyledTabs
            value={this.state.tabsValue}
            onChange={this.handleTabs}
            indicatorColor="secondary"
            textColor="primary"
            centered
          >
            <Tab label="SignUp" {...this.a11yProps(0)} />
            <Tab label="SignIn" {...this.a11yProps(1)} />
          </StyledTabs>

          <SwipeableViews
            containerStyle={{
              transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
            }}
            index={this.state.tabsValue}
            onChangeIndex={this.handleChangeIndex}
          >
            <TabPanel value={this.state.tabsValue} index={0}>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-amount">
                  Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  value={this.state.signUpName}
                  onChange={this.handleChange("signUpName")}
                  labelWidth={50}
                />
              </FormControl>
              <br />

              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-amount">
                  Phone Number
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  value={this.state.signUpPhoneNumber}
                  onChange={this.handleChange("signUpPhoneNumber")}
                  labelWidth={120}
                  placeholder="xxxxxxxxxx"
                  startAdornment={
                    <InputAdornment position="start">+91</InputAdornment>
                  }
                />
              </FormControl>
              <br />

              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-amount">
                  Email
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  value={this.state.signUpEmail}
                  onChange={this.handleChange("signUpEmail")}
                  labelWidth={100}
                />
              </FormControl>
              <br />

              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-amount">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={this.state.showSignUpPassword ? "text" : "password"}
                  value={this.state.signUpPassword}
                  onChange={this.handleChange("signUpPassword")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowSignUpPassword}
                        onMouseDown={this.handleMouseDownSignUpPassword}
                        edge="end"
                      >
                        {this.state.showSignUpPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
              <br />
              <br />

              <p className={classes.agreeText}>
                By Signing Up, I agree to the{" "}
                <a target="_blank" href="/privacy-policy">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a target="_blank" href="/terms-of-use">
                  Terms of Use
                </a>
                .
              </p>
              <div className={classes.recaptchaWrapper}>
                <ReCAPTCHA
                  sitekey={REACT_APP_SITE_KEY}
                  onChange={this.onSignupRecaptchaChange}
                  ref={this.signupRecaptcharRef}
                />
              </div>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.handleSignUpModalOpen}
              >
                SIGN UP
              </Button>

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.signUpModal}
                open={this.state.signUpModalOpen}
                onClose={this.handleSignUpModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={this.state.signUpModalOpen}>
                  <div className={classes.signUpModalPaper}>
                    <h3 className={classes.otpHeading}>
                      Enter OTP received on {this.state.signUpPhoneNumber}:{" "}
                    </h3>
                    <br />

                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="outlined-adornment-amount">
                        OTP
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-OTP"
                        value={this.state.signUpOTP}
                        onChange={this.handleChange("signUpOTP")}
                        labelWidth={40}
                      />
                    </FormControl>
                    <br />
                    <div className={classes.recaptchaWrapper}>
                      <ReCAPTCHA
                        sitekey={REACT_APP_SITE_KEY}
                        onChange={this.onSignupResendRecaptchaChange}
                        ref={this.signupResendRecaptchaRef}
                      />
                    </div>
                    <br />
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={(e) => this.handleResendOtp(e, "signUp")}
                      className={classes.navButton1}
                    >
                      RESEND OTP
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={this.handleSignUp}
                      className={classes.navButton}
                    >
                      SUBMIT
                    </Button>
                  </div>
                </Fade>
              </Modal>
            </TabPanel>

            <TabPanel value={this.state.tabsValue} index={1}>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-amount">
                  Phone Number
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  value={this.state.signInPhoneNumber}
                  onChange={this.handleChange("signInPhoneNumber")}
                  labelWidth={120}
                  placeholder="xxxxxxxxxx"
                  startAdornment={
                    <InputAdornment position="start">+91</InputAdornment>
                  }
                />
              </FormControl>
              <br />
              <div className={classes.switchGroup}>
                OTP
                <Switch
                  checked={this.state.isLoginPassword}
                  onChange={this.handleSwitch}
                  name="checkedA"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                PASSWORD
              </div>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-amount">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={this.state.showSignInPassword ? "text" : "password"}
                  value={this.state.signInPassword}
                  onChange={this.handleChange("signInPassword")}
                  disabled={!this.state.isLoginPassword ? true : false}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowSignInPassword}
                        onMouseDown={this.handleMouseDownSignInPassword}
                        edge="end"
                      >
                        {this.state.showSignInPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
              <br />

              <div className={classes.buttonsRoot}>
                <div className={classes.recaptchaWrapper}>
                  <ReCAPTCHA
                    sitekey={REACT_APP_SITE_KEY}
                    onChange={this.onLoginRecaptchaChange}
                    ref={this.loginRecaptchaRef}
                  />
                </div>
                <br />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.handleSignInModalOpen}
                  disabled={this.state.isLoginPassword ? true : false}
                >
                  REQUEST OTP
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.handlePasswordSignIn}
                  className={classes.navButton}
                  disabled={this.state.signInPassword === "" ? true : false}
                >
                  SIGNIN
                </Button>
              </div>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.signUpModal}
                open={this.state.signInModalOpen}
                onClose={this.handleSignInModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={this.state.signInModalOpen}>
                  <div className={classes.signUpModalPaper}>
                    <h3 className={classes.otpHeading}>
                      Enter OTP received on {this.state.signInPhoneNumber}:{" "}
                    </h3>
                    <br />

                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="outlined-adornment-amount">
                        OTP
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-OTP"
                        value={this.state.signInOTP}
                        onChange={this.handleChange("signInOTP")}
                        labelWidth={40}
                      />
                    </FormControl>
                    <br />
                    <div className={classes.recaptchaWrapper}>
                      <ReCAPTCHA
                        sitekey={REACT_APP_SITE_KEY}
                        onChange={this.onLoginResendRecaptchaChange}
                        ref={this.loginResendRecaptchaRef}
                      />
                    </div>
                    <br />
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={(e) => this.handleResendOtp(e, "signIn")}
                      className={classes.navButton1}
                    >
                      RESEND OTP
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={this.handleOTPSignIn}
                      className={classes.navButton}
                    >
                      SUBMIT
                    </Button>
                  </div>
                </Fade>
              </Modal>
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { currentPage, currentStep } = state.routing;
  const {
    signUpToken,
    verifyOTPSuccess,
    passwordSignInSuccess,
    jT,
    isLoggedIn,
    passwordSignInFailureReason,
  } = state.auth;
  const { images, postingDetails } = state.posting;
  return {
    currentPage,
    currentStep,
    signUpToken,
    verifyOTPSuccess,
    passwordSignInSuccess,
    jT,
    images,
    postingDetails,
    isLoggedIn,
    passwordSignInFailureReason,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (currentPage) => {
    dispatch(routingActions.setCurrentPage(currentPage));
  },
  setCurrentStep: (currentStep) => {
    dispatch(routingActions.setCurrentStep(currentStep));
  },
  requestOTP: (phoneNumber, recaptchaToken) => {
    dispatch(authActions.requestOTP(phoneNumber, recaptchaToken));
  },
  signUp: (name, phoneNumber, password, recaptchaToken) => {
    dispatch(authActions.signUp(name, phoneNumber, password, recaptchaToken));
  },
  passwordSignIn: (phoneNumber, password, recaptchaToken) => {
    dispatch(authActions.passwordSignIn(phoneNumber, password, recaptchaToken));
  },
  otpSignIn: (phoneNumber, otp) => {
    dispatch(authActions.otpSignIn(phoneNumber, otp));
  },
  verifyOTP: (phoneNumber, otp) => {
    dispatch(authActions.verifyOTP(phoneNumber, otp));
  },
  posting: (postingDetails, images, jT) => {
    dispatch(postingActions.posting(postingDetails, images, jT));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(LoginPage))
);
