import React, { useState, useEffect } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/index.min.css';
import Link from "next/link";
import { authActions } from '../redux/_actions';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import selftunnel from '../public/images/logo14.svg';
import selftunnel_mobile from '../public/images/logo_mobile.svg';
import Modal from '@material-ui/core/Modal';
import LoginPage from './LoginPage';
import Chip from '@material-ui/core/Chip';
import { useRouter } from 'next/router'
import Image from 'next/image';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    width: '100%',
  },
  menuButton: {
    [theme.breakpoints.up(500)]: {
    marginRight: theme.spacing(2),
    }
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    [theme.breakpoints.up(500)]: {
      marginRight: theme.spacing(2),
    },
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    '& svg':{
      marginLeft: '-25px',
      color: 'black !important'
    },
    display: 'flex',
    cursor: 'pointer'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    '& h4': {
      cursor: 'pointer !important'
    },
    alignItems: 'center'
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appBar: {
    position: 'fixed !important',
    top: 0,
  },
  appBarHomePage: {
    position: 'fixed !important',
    top: 0,
    background: 'transparent !important',
    boxShadow: 'none',
  },
  logoImage: {
      maxWidth: '160px',
  },
  logoImageMobile: {
      maxWidth: '40px',
  },
  desktopLogo: {
    [theme.breakpoints.down(500)]: {
      display: 'none'
    },
  },
  mobileLogo: {
    [theme.breakpoints.up(500)]: {
      display: 'none'
    },
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 'calc(100% - 64px)'
  },
  closeMap:{
    position: 'absolute',
    top: '20px',
    right:'20px',
    zIndex: 1111111
  },
  displayNone: {
    display: 'none'
  }
}));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function Appbar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);


  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if(props.isLoggedIn && open === true){
      router.push('/profile');
      setOpen(false);
    }
  });

  const handleProfileClick = (event) => {
    setMobileMoreAnchorEl(null);
    setAnchorEl(null);
    if(props.isLoggedIn){
      router.push('/profile');
    }
    else{
      setOpen(true);
    }
  }
  const handleLogout = (event) => {
    setMobileMoreAnchorEl(null);
    setAnchorEl(null);
    localStorage.removeItem('user');
    props.logout();
    if(window.location.pathname === '/profile'){
      router.push('/')
    }

  }
  const pushProfile = (event) => {
    router.push('/profile');
  }


  const handleModalClose = (event) => {
    setOpen(false);
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
      {props.isLoggedIn && (<MenuItem onClick={handleLogout}>Logout</MenuItem>)}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link href={'/post/rent'} >
      <MenuItem onClick={handleMobileMenuClose}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <HomeWorkIcon />
        </IconButton>
        <p>POST LISTING</p>

      </MenuItem>
      </Link>
      <Link href={'/properties/bangalore'} >
      <MenuItem onClick={handleMobileMenuClose}>
      
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <SearchIcon />
        </IconButton>
        <p>FIND ROOM</p>
      
      </MenuItem>
      </Link>
      <MenuItem onClick={handleProfileClick}> 
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>PROFILE</p>
      </MenuItem>
      {props.isLoggedIn && (
      <MenuItem onClick={handleLogout} > 
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>LOGOUT</p>
      </MenuItem>
      )}
    </Menu>
  );

  const router = useRouter();

  function handleSearch(result){
    if(props.searchRoomType){
      const cities = [
        "Bengaluru",
        "Pune",
        "Hyderabad",
        "Mumbai",
        "Gurgaon",
        "Noida",
        "Ahmedabad",
        "Delhi"
      ];
      let currentCity;
      cities.some(city => {
        if (result?.terms?.find(term => term.value === city)) {
          currentCity = city;
          return true;
        }
      });
      if (currentCity && currentCity !== result.structured_formatting.main_text) {
        router.push(
          `${props.searchRoomType}/${currentCity}/${result.structured_formatting.main_text}`
        );
      } else {
        router.push(`${props.searchRoomType}/${result.structured_formatting.main_text}`);
      }
    }
    else {
      router.push('/properties/' + result.structured_formatting.main_text.replaceAll(/, /g, "-").replaceAll(/ /g, "-"));
    }
    textInput.current.changeValue("");
  }

  const textInput = React.useRef();

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={ !props.showTopBar ? classes.appBar : classes.appBarHomePage}>
        
        <Toolbar>
          <Modal
            open={open}
            onClose={handleModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={modalStyle} className={classes.paper}>
              <div>
              <Chip
                color="secondary"
                onDelete={handleModalClose}
                className={classes.closeMap}
              />
              <LoginPage />
              </div>

            </div>
          </Modal>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <div className={classes.desktopLogo}>
              <Link href="/">
                <Image
                  src='/images/logo14.svg'
                  alt="FindMyRoom"
                  width={160}
                  height={53}
                />
              </Link> 
            </div> 
            <div className={classes.mobileLogo}>
              <Link href="/">
                <Image
                  src='/images/logo_mobile.svg'
                  alt="FindMyRoom"
                  width={40}
                  height={53}
                />
              </Link> 
            </div> 
          </IconButton>
          {
            !props.showTopBar && (
              <div className={ !props.showTopBar ?  classes.search : classes.displayNone}>
              <GooglePlacesAutocomplete
                onSelect={handleSearch}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['in'],
                  },
                  types:  ['(regions)'] 
                }}
                displayPoweredByGoogle={true}
                placeholder="Search by Locality"
                ref={textInput}
              /> 
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
            </div>
            )
          }
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
{/*             <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>text-decoration: none;
    color: white;
            </IconButton> */}

            <Link href="/post/rent" style={{textDecoration: "none", color: "white", marginRight: '20px', display: 'flex'}}><Button variant="outlined" style={{ textDecoration: "none", width: '140px', marginRight: '20px',color: "white", border: '2px solid white' }}>Post Listing</Button></Link>
            <Link href="/properties/bangalore" style={{textDecoration: "none", color: "white", marginRight: '10px', display: 'flex'}}><h4 style={{textDecoration: "none", width: '120px'}}>Find a Room</h4></Link>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen} 
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            {
              props.showTopBar && (
                <Link href="/post/rent" style={{textDecoration: "none", color: "white", marginRight: '20px', display: 'flex'}}><h4 style={{ textDecoration: "none", width: '120px',color: "white" }}>Post Listing</h4></Link>
              )
            }
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}



const mapStateToProps = (state) => {
  const { isLoggedIn } = state.auth;
  return {
    isLoggedIn,
  };
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
      dispatch(authActions.logout()) 
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Appbar);