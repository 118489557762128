import React from "react";
import Appbar from "./appBar";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Grid from "@material-ui/core/Grid";
import Link from "next/link";
import Chatbot from "./ChatBot";
import Head from "next/head";

const styles = (theme) => ({
  fullPage: {
    width: "100%",
  },
  footer: {
    width: "100%",
    background: "#3f3f3f",
    textAlign: "left",
    padding: "5%",
    position: "absolute",
    zIndex: 100,
    [theme.breakpoints.down(600)]: {
      padding: "6%",
    },
  },
  footer1: {
    width: "100%",
    background: "#757474",
    textAlign: "left",
    padding: "5%",
    position: "relative",
    zIndex: 100,
    [theme.breakpoints.down(600)]: {
      "& .MuiGrid-container": {
        flexWrap: "inherit !important",
        overflowX: "auto",
        whiteSpace: "nowrap",
      },
    },
    paddingBottom: "2rem",
  },
  socialIcons: {
    display: "flex",
    width: "fit-content",
    margin: "auto",
    padding: "20px 5%",
  },
  socialIcon: {
    padding: "0px 20px",
    cursor: "pointer",
    color: "#fff",
  },
  footerContent: {
    display: "flex",
    width: "fit-content",
    margin: "auto",
    marginBottom: "-2rem",
    padding: "20px",
  },
  footerContents: {
    display: "flex",
    width: "fit-content",
    margin: "auto",
    marginBottom: "2rem",
    "& p": {
      textAlign: "center",
    },
  },
  footerHeading: {
    color: "white",
    opacity: 1,
    fontSize: "16px",
  },
  footerHeading1: {
    color: "white",
    opacity: 1,
    textAlign: "center",
    fontSize: "24px",
  },
  footerLinks: {
    margin: "6px 0",
    color: "white",
    opacity: 0.5,
    paddingTop: "0.5rem",
  },
  pageContent: {
    marginTop: "128px",
  },
  citiesContainer: {
    overflowX: "auto",
    whiteSpace: "nowrap",
    flexWrap: "inherit !important",
  },
  footerDiv: {
    position: "relative",
  },
});
// Entry point into the app
class Layout extends React.Component {
  state = {
    pathname: typeof window !== "undefined" && window.location.pathname,
    open: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ open: true });
    }, 50);
  }

  getBangaloreLinks = (roomType, classes) => {
    let roomName = "";
    switch (roomType) {
      case "roommate":
        roomName = "Roommates";
        break;
      case "flatmate":
        roomName = "Flatmates";
        break;
      case "room":
        roomName = "Rooms";
        break;
      case "co-living":
        roomName = "Co-living spaces";
        break;
      case "pg":
        roomName = "PG";
        break;
      case "entire-house":
        roomName = "Entire houses";
        break;
      default:
        roomName = "Rooms";
    }
    return (
      <>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/hsr-layout`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in HSR Layout</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/bellandur`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Bellandur</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/electronic-city`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Electronic City</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/btm-layout`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in BTM Layout</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/koramangala`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Koramangala</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/manayata-tech-park-nagavara`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Manyata Tech Park</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/marathahalli`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Marathahalli</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/jp-nagar`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in JP Nagar</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/rt-nagar`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in RT Nagar</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/kadugodi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Kadugodi</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/indiranagar`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Indirangar</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/yeshwantpur`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Yeshwantpur</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/kodihalli`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Kodihalli</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/banashankari`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Banashankari</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/basavanagudi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Basavanagudi</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/ulsoor`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Ulsoor</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/hebbal`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Hebbal</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/jayanagar`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Jayanagar</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/cv-raman-nagar`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in CV Raman Nagar</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/itpl`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in ITPL</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/bangalore/sarjapur-road`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Sarjapur Road</p>
        </a>
      </>
    );
  };

  getPuneLinks = (roomType, classes) => {
    let roomName = "";
    switch (roomType) {
      case "roommate":
        roomName = "Roommates";
        break;
      case "flatmate":
        roomName = "Flatmates";
        break;
      case "room":
        roomName = "Rooms";
        break;
      case "co-living":
        roomName = "Co-living spaces";
        break;
      case "pg":
        roomName = "PG";
        break;
      case "entire-house":
        roomName = "Entire houses";
        break;
      default:
        roomName = "Rooms";
    }
    return (
      <>
        <a href={`https://findmyroom.in/${roomType}/pune/wakad`} style={{ textDecoration: "none" }}>
          <p className={classes.footerLinks}>{roomName} in Wakad</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/hinjewadi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Hinjewadi</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/kharadi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Kharadi</p>
        </a>
        <a href={`https://findmyroom.in/${roomType}/pune/Baner-Gaon-Baner`} style={{ textDecoration: "none" }}>
          <p className={classes.footerLinks}>{roomName} in Baner</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/kothrud`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Kothrud</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/karve-nagar`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Karve Nagar</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/kharadi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Kharadi</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/vishal-nagar`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Vishal Nagar</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/fursungi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Fursungi</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/pune-nagar-road`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Pune Nagar Road</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/magarpatta-city`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Magarpatta City</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/koregaon-park`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Koregaon Park</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/mahalunge`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Mahalunge</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/hadapsar`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Hadapsar</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/vadgaon-budruk`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Vadgaon Budruk</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/wadgaon-sheri`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Wadgaon Sheri</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/talawade`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Talawade</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/pimple-saudagar`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Pimple Saudagar</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/pune/viman-nagar`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Viman Nagar</p>
        </a>
      </>
    );
  };

  getMumbaiLinks = (roomType, classes) => {
    let roomName = "";
    switch (roomType) {
      case "roommate":
        roomName = "Roommates";
        break;
      case "flatmate":
        roomName = "Flatmates";
        break;
      case "room":
        roomName = "Rooms";
        break;
      case "co-living":
        roomName = "Co-living spaces";
        break;
      case "pg":
        roomName = "PG";
        break;
      case "entire-house":
        roomName = "Entire houses";
        break;
      default:
        roomName = "Rooms";
    }
    return (
      <>
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/jogeshwari-east`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Jogeshwari East</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/chembur`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Chembur</p>
        </a>
        {/* <a href={`https://findmyroom.in/${roomType}/mumbai/sion`} style={{ textDecoration: "none" }}><p className={classes.footerLinks}>{roomName} in Sion</p></a> */}
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/ghatkopar`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Ghatkopar</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/kurla-west`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Kurla West</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/andheri-east`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Andheri East</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/bandra`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Bandra</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/malad-east`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Malad East</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/bhandup-west`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Bhandup West</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/santacruz-east`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Santacruz East</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/ghatkopar-east`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Ghatkopar East</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/bandra-west`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Bandra West</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/worli`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Worli</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/tardeo`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Tardeo</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/navi-mumbai`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Navi Mumbai</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/goregaon`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Goregaon</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/mumbai/lokhandwala-complex`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>
            {roomName} in Lokhandwala Complex
          </p>
        </a>
      </>
    );
  };

  getHyderabadLinks = (roomType, classes) => {
    let roomName = "";
    switch (roomType) {
      case "roommate":
        roomName = "Roommates";
        break;
      case "flatmate":
        roomName = "Flatmates";
        break;
      case "room":
        roomName = "Rooms";
        break;
      case "co-living":
        roomName = "Co-living spaces";
        break;
      case "pg":
        roomName = "PG";
        break;
      case "entire-house":
        roomName = "Entire houses";
        break;
      default:
        roomName = "Rooms";
    }
    return (
      <>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/madhapur`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Madhapur</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/moosapet`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Moosapet</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/gachibowli`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Gachibowli</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/begumpet`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Begumpet</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/miyapur`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Miyapur</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/kukatpally`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Kukatpally</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/manikonda`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Manikonda</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/hafeezpet`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Hafeezpet</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/financial-district`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>
            {roomName} in Financial District
          </p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/hitech-city`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Hitech City</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/kphb`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in KPHB</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/kompally`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Kompally</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/kondapur`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Kondapur</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/banjara-hills`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Banjara Hills</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/ameerpet`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Ameerpet</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/hyderabad/somajiguda`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Somajiguda</p>
        </a>
      </>
    );
  };

  getGurgaonLinks = (roomType, classes) => {
    let roomName = "";
    switch (roomType) {
      case "roommate":
        roomName = "Roommates";
        break;
      case "flatmate":
        roomName = "Flatmates";
        break;
      case "room":
        roomName = "Rooms";
        break;
      case "co-living":
        roomName = "Co-living spaces";
        break;
      case "pg":
        roomName = "PG";
        break;
      case "entire-house":
        roomName = "Entire houses";
        break;
      default:
        roomName = "Rooms";
    }
    return (
      <>
      <a href={`https://findmyroom.in/${roomType}/gurgaon/civil-lanes-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>{roomName} in Civil Lanes</p>
        </a>
<a href={`https://findmyroom.in/${roomType}/gurgaon/patel-nagar-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>{roomName} in patel nagar</p>
        </a>
<a href={`https://findmyroom.in/${roomType}/gurgaon/sultanpur-gurgaon`}
          style={{ textDecoration: "none" }}>
<p className={classes.footerLinks}>{roomName} in sultanpur</p>
        </a>
<a href={`https://findmyroom.in/${roomType}/gurgaon/dlf-city-gurgaon`}
          style={{ textDecoration: "none" }}>
             <p className={classes.footerLinks}>{roomName} in DLF City</p>
        </a>
<a href={`https://findmyroom.in/${roomType}/gurgaon/south-city-1-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>{roomName} in South City 1</p>
        </a>
<a href={`https://findmyroom.in/${roomType}/gurgaon/sohna-road-gurgaon`}
          style={{ textDecoration: "none" }}>
  <p className={classes.footerLinks}>{roomName} in Sohna Road</p>
        </a>
<a href={`https://findmyroom.in/${roomType}/gurgaon/palam-vihar-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>{roomName} in Palam Vihar</p>
        </a>
<a href={`https://findmyroom.in/${roomType}/gurgaon/mg-road-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>{roomName} in MG Road</p>
        </a>
<a href={`https://findmyroom.in/${roomType}/gurgaon/sushant-lok-1-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>{roomName} in Sushant Lok 1</p>
        </a>
<a href={`https://findmyroom.in/${roomType}/gurgaon/south-city-2-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>{roomName} in South City 2</p>
        </a>
<a href={`https://findmyroom.in/${roomType}/gurgaon/nirvana-country-gurgaon`}
          style={{ textDecoration: "none" }}>
  <p className={classes.footerLinks}>{roomName} in Nirvana Country</p>
        </a>
<a href={`https://findmyroom.in/${roomType}/gurgaon/golf-course-extension-gurgaon`}
          style={{ textDecoration: "none" }}>
     <p className={classes.footerLinks}>
            {roomName} in Golf Course Extension </p> </a>
<a href={`https://findmyroom.in/${roomType}/gurgaon/gurgaon-sector-82-gurgaon`}
          style={{ textDecoration: "none" }}>   
          <p className={classes.footerLinks}>{roomName} in Gurgaon Sector 82</p>
        </a>
<a href={`https://findmyroom.in/${roomType}/gurgaon/golf-course-road-gurgaon`}
          style={{ textDecoration: "none" }}>  
          <p className={classes.footerLinks}>{roomName} in Golf Course Road</p>
        </a>
      </>
    );
  };

  getNoidaLinks = (roomType, classes) => {
    let roomName = "";
    switch (roomType) {
      case "roommate":
        roomName = "Roommates";
        break;
      case "flatmate":
        roomName = "Flatmates";
        break;
      case "room":
        roomName = "Rooms";
        break;
      case "co-living":
        roomName = "Co-living spaces";
        break;
      case "pg":
        roomName = "PG";
        break;
      case "entire-house":
        roomName = "Entire houses";
        break;
      default:
        roomName = "Rooms";
    }
    return (
      <>
        <a
          href={`https://findmyroom.in/${roomType}/noida/sector-1-noida-extension`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Sector- 1</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/noida/sector-15-noida`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Sector- 15</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/noida/sector-22-noida`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Sector- 22</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/noida/sector-55-and-56-noida`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Sector- 55 and 56</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/noida/sector-47-noida`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Sector- 47</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/noida/sector-137-noida`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Sector-137</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/noida/sector-44-noida`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Sector- 44</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/noida/sector-39-noida`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Sector -39</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/noida/sector-19-noida`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Sector-19</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/noida/sector-75-and-76-noida`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Sector 75 & 76</p>
        </a>
      </>
    );
  };

  getAhmedabadLinks = (roomType, classes) => {
    let roomName = "";
    switch (roomType) {
      case "roommate":
        roomName = "Roommates";
        break;
      case "flatmate":
        roomName = "Flatmates";
        break;
      case "room":
        roomName = "Rooms";
        break;
      case "co-living":
        roomName = "Co-living spaces";
        break;
      case "pg":
        roomName = "PG";
        break;
      case "entire-house":
        roomName = "Entire houses";
        break;
      default:
        roomName = "Rooms";
    }
    return (
      <>
        <a
          href={`https://findmyroom.in/${roomType}/ahmedabad/satellite-ahmedabad`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Satellite</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/ahmedabad/sg-highway-ahmedabad`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in SG Highway</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/ahmedabad/prahlad-nagar-ahmedabad`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Prahlad Nagar</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/ahmedabad/gota-ahmedabad`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Gota</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/ahmedabad/bodak-dev-ahmedabad`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Bodak Dev</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/ahmedabad/chandkheda-ahmedabad`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Chandkheda</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/ahmedabad/thaltej-ahmedabad`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Thaltej</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/ahmedabad/maninagar-ahmedabad`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Maninagar</p>
        </a>
      </>
    );
  };

  getDelhiLinks = (roomType, classes) => {
    let roomName = "";
    switch (roomType) {
      case "roommate":
        roomName = "Roommates";
        break;
      case "flatmate":
        roomName = "Flatmates";
        break;
      case "room":
        roomName = "Rooms";
        break;
      case "co-living":
        roomName = "Co-living spaces";
        break;
      case "pg":
        roomName = "PG";
        break;
      case "entire-house":
        roomName = "Entire houses";
        break;
      default:
        roomName = "Rooms";
    }
    return (
      <>
         <a
          href={`https://findmyroom.in/${roomType}/delhi/hauz-khas-delhi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Hauz Khas</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/delhi/rajouri-garden-delhi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Rajouri Garden</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/delhi/vasant-kunj-delhi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Vasant Kunj</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/delhi/lajpat-nagar-delhi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Lajpat Nagar</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/delhi/nehru-place-delhi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Nehru Place</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/delhi/mayur-vihar-delhi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Mayur Vihar</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/delhi/karol-bagh-delhi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Karol Bagh</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/delhi/dwarka-delhi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Dwarka</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/delhi/south-extension-south-ex-delhi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in South Extension</p>
        </a>
        <a
          href={`https://findmyroom.in/${roomType}/delhi/greater-kailash-delhi`}
          style={{ textDecoration: "none" }}
        >
          <p className={classes.footerLinks}>{roomName} in Greater Kailash</p>
        </a>
      </>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.fullPage}>
        <Head>
          <link rel="shortcut icon" href="https://findmyroom.in/favicon.ico" />
        </Head>
        <Appbar
          updateSearch={(result) => this.searchResult(result)}
          isHomePage={this.props.isHomePage}
          showTopBar={this.props.showTopBar}
          searchRoomType={this.props.searchRoomType}
        />
        <div className={!this.props.isHomePage && classes.pageContent}>
          {this.props.children}
        </div>
        <section className={classes.footer1}>
          {!this.props.currentCity ? (
            <>
              <p className={classes.footerHeading1}>Search in Top Cities</p>
              {this.props.searchRoomType === "roommate" && (
                <Grid container spacing={3} className={classes.citiesContainer}>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/roommate/bangalore"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Roommates in Bangalore
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/hsr-layout"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in HSR Layout
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/bellandur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Bellandur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/btm-layout"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in BTM Layout
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/koramangala"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Koramangala
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/manayata-tech-park-nagavara"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Manyata Tech Park
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/marathahalli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Marathahalli
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/jp-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in JP Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/rt-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in RT Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/kadugodi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Kadugodi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/indiranagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Indiranagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/yeshwantpur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Yeshwantpur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/kodihalli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Kodihalli
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/banashankari"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Banashankari
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/basavanagudi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Basavanagudi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/ulsoor"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Roommates in Ulsoor</p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/hebbal"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Roommates in Hebbal</p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/jayanagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Jayanagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/cv-raman-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in CV Raman Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/itpl"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Roommates in ITPL</p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/bangalore/sarjapur-road"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Sarjapur Road
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a href="https://findmyroom.in/roommate/pune" style={{ textDecoration: "none" }}>
                      <p className={classes.footerHeading}>Roommates in Pune</p>{" "}
                    </a>

                    <a
                      href="https://findmyroom.in/roommate/pune/wakad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Roommates in Wakad</p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/hinjewadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Hinjewadi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/kharadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Kharadi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/Baner-Gaon-Baner"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Roommates in Baner</p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/kothrud"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Kothrud
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/karve-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Karve Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/kharadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Kharadi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/vishal-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Vishal Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/fursungi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Fursungi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/wadgaon-sheri"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Wadgaon Sheri
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/pune-nagar-road"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Pune Nagar Road
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/magarpatta-city"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Magarpatta City
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/koregaon-park"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Koregaon Park
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/mahalunge"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Mahalunge
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/hadapsar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Hadapsar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/talawade"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Talawade
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/pimple-saudagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Pimple Saudagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/vadgaon-budruk"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Vadgaon Budruk
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/pune/viman-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Viman Nagar
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/roommate/mumbai"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Roommates in Mumbai
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/jogeshwari-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Jogeshwari East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/chembur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Chembur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/ghatkopar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Ghatkopar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/kurla-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Kurla West
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/andheri-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Andheri East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/bandra"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Roommates in Bandra</p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/malad-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Malad East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/bhandup-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Bhandup West
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/santacruz-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Santacruz East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/ghatkopar-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Ghatkopar East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/bandra-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Bandra West
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/worli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Roommates in Worli</p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/tardeo"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Roommates in Tardeo</p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/navi-mumbai"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Navi Mumbai
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/goregaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Goregaon
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/mumbai/lokhandwala-complex"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Lokhandwala Complex
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Roommates in Hyderabad
                      </p>
                    </a>

                    <a
                      href="https://findmyroom.in/roommate/hyderabad/madhapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Madhapur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad/moosapet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Moosapet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad/gachibowli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Gachibowli
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad/begumpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Begumpet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad/miyapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Miyapur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad/kukatpally"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Kukatpally
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad/manikonda"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Manikonda
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad/hafeezpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Hafeezpet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad/financial-district"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Financial District
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad/hitech-city"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Hitech City
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad/kphb"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Roommates in KPHB</p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad/kompally"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Kompally
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad/kondapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Kondapur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad/banjara-hills"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Banjara Hills
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad/ameerpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Ameerpet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/roommate/hyderabad/somajiguda"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Roommates in Somajiguda
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/roommate/gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Roommates in Gurgaon
                      </p>
                    </a>

                    <a href={`https://findmyroom.in/roommate/gurgaon/civil-lanes-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Roommates in Civil Lanes</p>
        </a>
<a href={`https://findmyroom.in/roommate/gurgaon/patel-nagar-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>Roommates in patel nagar</p>
        </a>
<a href={`https://findmyroom.in/roommate/gurgaon/sultanpur-gurgaon`}
          style={{ textDecoration: "none" }}>
<p className={classes.footerLinks}>Roommates in sultanpur</p>
        </a>
<a href={`https://findmyroom.in/roommate/gurgaon/dlf-city-gurgaon`}
          style={{ textDecoration: "none" }}>
             <p className={classes.footerLinks}>Roommates in DLF City</p>
        </a>
<a href={`https://findmyroom.in/roommate/gurgaon/south-city-1-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>Roommates in South City 1</p>
        </a>
<a href={`https://findmyroom.in/roommate/gurgaon/sohna-road-gurgaon`}
          style={{ textDecoration: "none" }}>
  <p className={classes.footerLinks}>Roommates in Sohna Road</p>
        </a>
<a href={`https://findmyroom.in/roommate/gurgaon/palam-vihar-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>Roommates in Palam Vihar</p>
        </a>
<a href={`https://findmyroom.in/roommate/gurgaon/mg-road-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Roommates in MG Road</p>
        </a>
<a href={`https://findmyroom.in/roommate/gurgaon/sushant-lok-1-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Roommates in Sushant Lok 1</p>
        </a>
<a href={`https://findmyroom.in/roommate/gurgaon/south-city-2-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Roommates in South City 2</p>
        </a>
<a href={`https://findmyroom.in/roommate/gurgaon/nirvana-country-gurgaon`}
          style={{ textDecoration: "none" }}>
  <p className={classes.footerLinks}>Roommates in Nirvana Country</p>
        </a>
<a href={`https://findmyroom.in/roommate/gurgaon/golf-course-extension-gurgaon`}
          style={{ textDecoration: "none" }}>
     <p className={classes.footerLinks}>
            Roommates in Golf Course Extension </p> </a>
<a href={`https://findmyroom.in/roommate/gurgaon/gurgaon-sector-82-gurgaon`}
          style={{ textDecoration: "none" }}>   
          <p className={classes.footerLinks}>Roommates in Gurgaon Sector 82</p>
        </a>
<a href={`https://findmyroom.in/roommate/gurgaon/golf-course-road-gurgaon`}
          style={{ textDecoration: "none" }}>  
          <p className={classes.footerLinks}>Roommates in Golf Course Road</p>
        </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/roommate/noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Roommates in Noida
                      </p>
                    </a>
                    <a
                    href="https://findmyroom.in/roommate/noida/sector-1-noida-extension"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Sector- 1</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/noida/sector-15-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Sector- 15</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/noida/sector-22-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Sector- 22</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/noida/sector-55-and-56-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Sector- 55 and 56</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/noida/sector-47-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Sector- 47</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/noida/sector-137-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in  Sector-137</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/noida/sector-44-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Sector- 44</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/noida/sector-39-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Sector -39</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/noida/sector-19-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Sector-19</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/noida/sector-75-and-76-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Sector 75 & 76</p>
                  </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/roommate/ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Roommates in Ahmedabad
                      </p>
                    </a>

                    <a
                    href="https://findmyroom.in/roommate/ahmedabad/satellite-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Satellite</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/ahmedabad/sg-highway-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in SG Highway</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/ahmedabad/prahlad-nagar-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Prahlad Nagar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/ahmedabad/gota-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Gota</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/ahmedabad/bodak-dev-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Bodak Dev</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/ahmedabad/chandkheda-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Chandkheda</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/ahmedabad/thaltej-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Thaltej</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/ahmedabad/maninagar-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Maninagar</p>
                  </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/roommate/delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Roommates in Delhi
                      </p>
                    </a>
                    <a
                    href="https://findmyroom.in/roommate/delhi/hauz-khas-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Hauz Khas</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/delhi/rajouri-garden-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Rajouri Garden</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/delhi/vasant-kunj-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Vasant Kunj</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/delhi/lajpat-nagar-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Lajpat Nagar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/delhi/nehru-place-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Nehru Place</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/delhi/mayur-vihar-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Mayur Vihar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/delhi/karol-bagh-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Karol Bagh</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/delhi/dwarka-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Dwarka</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/delhi/south-extension-south-ex-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in South Extension</p>
                  </a>
                  <a
                    href="https://findmyroom.in/roommate/delhi/greater-kailash-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Roommates in Greater Kailash</p>
                  </a>
                  </Grid>
                </Grid>
              )}
              {this.props.searchRoomType === "flatmate" && (
                <Grid container spacing={3} className={classes.citiesContainer}>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Flatmates in Bangalore
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/hsr-layout"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in HSR Layout
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/bellandur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Bellandur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/btm-layout"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in BTM Layout
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/koramangala"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Koramangala
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/manayata-tech-park-nagavara"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Manyata Tech Park
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/marathahalli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Marathahalli
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/jp-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in JP Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/rt-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in RT Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/kadugodi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Kadugodi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/indiranagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Indiranagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/yeshwantpur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Yeshwantpur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/kodihalli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Kodihalli
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/banashankari"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Banashankari
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/basavanagudi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Basavanagudi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/ulsoor"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Flatmates in Ulsoor</p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/hebbal"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Flatmates in Hebbal</p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/jayanagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Jayanagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/cv-raman-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in CV Raman Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/itpl"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Flatmates in ITPL</p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/bangalore/sarjapur-road"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Sarjapur Road
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a href="https://findmyroom.in/flatmate/pune" style={{ textDecoration: "none" }}>
                      <p className={classes.footerHeading}>Flatmates in Pune</p>{" "}
                    </a>

                    <a
                      href="https://findmyroom.in/flatmate/pune/wakad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Flatmates in Wakad</p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/hinjewadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Hinjewadi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/kharadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Kharadi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/Baner-Gaon-Baner"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Flatmates in Baner</p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/kothrud"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Kothrud
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/karve-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Karve Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/kharadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Kharadi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/vishal-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Vishal Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/fursungi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Fursungi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/wadgaon-sheri"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Wadgaon Sheri
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/pune-nagar-road"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Pune Nagar Road
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/magarpatta-city"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Magarpatta City
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/koregaon-park"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Koregaon Park
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/mahalunge"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Mahalunge
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/hadapsar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Hadapsar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/talawade"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Talawade
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/pimple-saudagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Pimple Saudagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/vadgaon-budruk"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Vadgaon Budruk
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/pune/viman-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Viman Nagar
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Flatmates in Mumbai
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/jogeshwari-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Jogeshwari East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/chembur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Chembur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/ghatkopar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Ghatkopar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/kurla-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Kurla West
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/andheri-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Andheri East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/bandra"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Flatmates in Bandra</p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/malad-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Malad East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/bhandup-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Bhandup West
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/santacruz-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Santacruz East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/ghatkopar-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Ghatkopar East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/bandra-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Bandra West
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/worli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Flatmates in Worli</p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/tardeo"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Flatmates in Tardeo</p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/navi-mumbai"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Navi Mumbai
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/goregaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Goregaon
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/mumbai/lokhandwala-complex"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Lokhandwala Complex
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Flatmates in Hyderabad
                      </p>
                    </a>

                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/madhapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Madhapur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/moosapet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Moosapet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/gachibowli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Gachibowli
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/begumpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Begumpet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/miyapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Miyapur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/kukatpally"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Kukatpally
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/manikonda"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Manikonda
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/hafeezpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Hafeezpet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/financial-district"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Financial District
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/hitech-city"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Hitech City
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/kphb"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Flatmates in KPHB</p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/kompally"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Kompally
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/kondapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Kondapur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/banjara-hills"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Banjara Hills
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/ameerpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Ameerpet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/flatmate/hyderabad/somajiguda"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Flatmates in Somajiguda
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/flatmate/gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Flatmates in Gurgaon
                      </p>
                    </a>

                    <a href={`https://findmyroom.in/flatmate/gurgaon/civil-lanes-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Flatmates in Civil Lanes</p>
        </a>
<a href={`https://findmyroom.in/flatmate/gurgaon/patel-nagar-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>Flatmates in patel nagar</p>
        </a>
<a href={`https://findmyroom.in/flatmate/gurgaon/sultanpur-gurgaon`}
          style={{ textDecoration: "none" }}>
<p className={classes.footerLinks}>Flatmates in sultanpur</p>
        </a>
<a href={`https://findmyroom.in/flatmate/gurgaon/dlf-city-gurgaon`}
          style={{ textDecoration: "none" }}>
             <p className={classes.footerLinks}>Flatmates in DLF City</p>
        </a>
<a href={`https://findmyroom.in/flatmate/gurgaon/south-city-1-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>Flatmates in South City 1</p>
        </a>
<a href={`https://findmyroom.in/flatmate/gurgaon/sohna-road-gurgaon`}
          style={{ textDecoration: "none" }}>
  <p className={classes.footerLinks}>Flatmates in Sohna Road</p>
        </a>
<a href={`https://findmyroom.in/flatmate/gurgaon/palam-vihar-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>Flatmates in Palam Vihar</p>
        </a>
<a href={`https://findmyroom.in/flatmate/gurgaon/mg-road-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Flatmates in MG Road</p>
        </a>
<a href={`https://findmyroom.in/flatmate/gurgaon/sushant-lok-1-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Flatmates in Sushant Lok 1</p>
        </a>
<a href={`https://findmyroom.in/flatmate/gurgaon/south-city-2-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Flatmates in South City 2</p>
        </a>
<a href={`https://findmyroom.in/flatmate/gurgaon/nirvana-country-gurgaon`}
          style={{ textDecoration: "none" }}>
  <p className={classes.footerLinks}>Flatmates in Nirvana Country</p>
        </a>
<a href={`https://findmyroom.in/flatmate/gurgaon/golf-course-extension-gurgaon`}
          style={{ textDecoration: "none" }}>
     <p className={classes.footerLinks}>
            Flatmates in Golf Course Extension </p> </a>
<a href={`https://findmyroom.in/flatmate/gurgaon/gurgaon-sector-82-gurgaon`}
          style={{ textDecoration: "none" }}>   
          <p className={classes.footerLinks}>Flatmates in Gurgaon Sector 82</p>
        </a>
<a href={`https://findmyroom.in/flatmate/gurgaon/golf-course-road-gurgaon`}
          style={{ textDecoration: "none" }}>  
          <p className={classes.footerLinks}>Flatmates in Golf Course Road</p>
        </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/flatmate/noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Flatmates in Noida
                      </p>
                    </a>
                  <a
                    href="https://findmyroom.in/flatmate/noida/sector-1-noida-extension"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Sector- 1</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/noida/sector-15-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Sector- 15</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/noida/sector-22-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Sector- 22</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/noida/sector-55-and-56-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Sector- 55 and 56</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/noida/sector-47-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Sector- 47</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/noida/sector-137-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in  Sector-137</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/noida/sector-44-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Sector- 44</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/noida/sector-39-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Sector -39</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/noida/sector-19-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Sector-19</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/noida/sector-75-and-76-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Sector 75 & 76</p>
                  </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/flatmate/ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Flatmates in Ahmedabad
                      </p>
                    </a>

                    <a
                    href="https://findmyroom.in/flatmate/ahmedabad/satellite-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Satellite</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/ahmedabad/sg-highway-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in SG Highway</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/ahmedabad/prahlad-nagar-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Prahlad Nagar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/ahmedabad/gota-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Gota</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/ahmedabad/bodak-dev-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Bodak Dev</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/ahmedabad/chandkheda-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Chandkheda</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/ahmedabad/thaltej-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Thaltej</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/ahmedabad/maninagar-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Maninagar</p>
                  </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/flatmate/delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Flatmates in Delhi
                      </p>
                    </a>
                    <a
                    href="https://findmyroom.in/flatmate/delhi/hauz-khas-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Hauz Khas</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/delhi/rajouri-garden-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Rajouri Garden</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/delhi/vasant-kunj-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Vasant Kunj</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/delhi/lajpat-nagar-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Lajpat Nagar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/delhi/nehru-place-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Nehru Place</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/delhi/mayur-vihar-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Mayur Vihar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/delhi/karol-bagh-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Karol Bagh</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/delhi/dwarka-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Dwarka</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/delhi/south-extension-south-ex-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in South Extension</p>
                  </a>
                  <a
                    href="https://findmyroom.in/flatmate/delhi/greater-kailash-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Flatmates in Greater Kailash</p>
                  </a>
                  </Grid>
                </Grid>
              )}
              {this.props.searchRoomType === "room" && (
                <Grid container spacing={3} className={classes.citiesContainer}>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/room/bangalore"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Rooms in Bangalore
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/hsr-layout"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in HSR Layout</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/bellandur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Bellandur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/btm-layout"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in BTM Layout</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/koramangala"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Koramangala
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/manayata-tech-park-nagavara"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Manyata Tech Park</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/marathahalli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Marathahalli
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/jp-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in JP Nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/rt-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in RT Nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/kadugodi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Kadugodi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/indiranagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Indiranagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/yeshwantpur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Yeshwantpur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/kodihalli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Kodihalli</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/banashankari"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Banashankari
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/basavanagudi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Basavanagudi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/ulsoor"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Ulsoor</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/hebbal"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Hebbal</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/jayanagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Jayanagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/cv-raman-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in CV Raman Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/itpl"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in ITPL</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/bangalore/sarjapur-road"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Sarjapur Road
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a href="https://findmyroom.in/room/pune" style={{ textDecoration: "none" }}>
                      <p className={classes.footerHeading}>Rooms in Pune</p>{" "}
                    </a>

                    <a
                      href="https://findmyroom.in/room/pune/wakad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Wakad</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/hinjewadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Hinjewadi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/kharadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Kharadi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/Baner-Gaon-Baner"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Baner</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/kothrud"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Kothrud</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/karve-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Karve Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/kharadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Kharadi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/vishal-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Vishal Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/fursungi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Fursungi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/wadgaon-sheri"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Wadgaon Sheri
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/pune-nagar-road"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Pune Nagar Road
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/magarpatta-city"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Magarpatta City
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/koregaon-park"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Koregaon Park
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/mahalunge"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Mahalunge</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/hadapsar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Hadapsar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/talawade"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Talawade</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/pimple-saudagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Pimple Saudagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/vadgaon-budruk"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Vadgaon Budruk
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/pune/viman-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Viman Nagar
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a href="https://findmyroom.in/room/mumbai" style={{ textDecoration: "none" }}>
                      <p className={classes.footerHeading}>Rooms in Mumbai</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/jogeshwari-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Jogeshwari East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/chembur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Chembur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/ghatkopar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Ghatkopar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/kurla-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Kurla West</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/andheri-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Andheri East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/bandra"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Bandra</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/malad-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Malad East</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/bhandup-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Bhandup West
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/santacruz-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Santacruz East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/ghatkopar-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Ghatkopar East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/bandra-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Bandra West
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/worli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Worli</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/tardeo"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Tardeo</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/navi-mumbai"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Navi Mumbai
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/goregaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Goregaon</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/mumbai/lokhandwala-complex"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Lokhandwala Complex
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/room/hyderabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Rooms in Hyderabad
                      </p>
                    </a>

                    <a
                      href="https://findmyroom.in/room/hyderabad/madhapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Madhapur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/hyderabad/moosapet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Moosapet</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/hyderabad/gachibowli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Gachibowli</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/hyderabad/begumpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Begumpet</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/hyderabad/miyapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Miyapur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/hyderabad/kukatpally"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Kukatpally</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/hyderabad/manikonda"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Manikonda</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/hyderabad/hafeezpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Hafeezpet</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/hyderabad/financial-district"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Financial District
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/hyderabad/hitech-city"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Hitech City
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/hyderabad/kphb"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in KPHB</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/hyderabad/kompally"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Kompally</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/hyderabad/kondapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Kondapur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/hyderabad/banjara-hills"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Rooms in Banjara Hills
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/hyderabad/ameerpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Ameerpet</p>
                    </a>
                    <a
                      href="https://findmyroom.in/room/hyderabad/somajiguda"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rooms in Somajiguda</p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a href="https://findmyroom.in/room/gurgaon" style={{ textDecoration: "none" }}>
                      <p className={classes.footerHeading}>Rooms in Gurgaon</p>
                    </a>

                    <a href={`https://findmyroom.in/room/gurgaon/civil-lanes-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Rooms for Rent in Civil Lanes</p>
        </a>
        <a href={`https://findmyroom.in/room/gurgaon/civil-lanes-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Room for Rent in Civil Lanes</p>
        </a>
<a href={`https://findmyroom.in/room/gurgaon/patel-nagar-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>Room for Rent in patel nagar</p>
        </a>
<a href={`https://findmyroom.in/room/gurgaon/sultanpur-gurgaon`}
          style={{ textDecoration: "none" }}>
<p className={classes.footerLinks}>Room for Rent in sultanpur</p>
        </a>
<a href={`https://findmyroom.in/room/gurgaon/dlf-city-gurgaon`}
          style={{ textDecoration: "none" }}>
             <p className={classes.footerLinks}>Room for Rent in DLF City</p>
        </a>
<a href={`https://findmyroom.in/room/gurgaon/south-city-1-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>Room for Rent in South City 1</p>
        </a>
<a href={`https://findmyroom.in/room/gurgaon/sohna-road-gurgaon`}
          style={{ textDecoration: "none" }}>
  <p className={classes.footerLinks}>Room for Rent in Sohna Road</p>
        </a>
<a href={`https://findmyroom.in/room/gurgaon/palam-vihar-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>Room for Rent in Palam Vihar</p>
        </a>
<a href={`https://findmyroom.in/room/gurgaon/mg-road-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Room for Rent in MG Road</p>
        </a>
<a href={`https://findmyroom.in/room/gurgaon/sushant-lok-1-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Room for Rent in Sushant Lok 1</p>
        </a>
<a href={`https://findmyroom.in/room/gurgaon/south-city-2-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Room for Rent in South City 2</p>
        </a>
<a href={`https://findmyroom.in/room/gurgaon/nirvana-country-gurgaon`}
          style={{ textDecoration: "none" }}>
  <p className={classes.footerLinks}>Room for Rent in Nirvana Country</p>
        </a>
<a href={`https://findmyroom.in/room/gurgaon/golf-course-extension-gurgaon`}
          style={{ textDecoration: "none" }}>
     <p className={classes.footerLinks}>
            Room for Rent in Golf Course Extension </p> </a>
<a href={`https://findmyroom.in/room/gurgaon/gurgaon-sector-82-gurgaon`}
          style={{ textDecoration: "none" }}>   
          <p className={classes.footerLinks}>Room for Rent in Gurgaon Sector 82</p>
        </a>
<a href={`https://findmyroom.in/room/gurgaon/golf-course-road-gurgaon`}
          style={{ textDecoration: "none" }}>  
          <p className={classes.footerLinks}>Room for Rent in Golf Course Road</p>
        </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a href="https://findmyroom.in/room/noida" style={{ textDecoration: "none" }}>
                      <p className={classes.footerHeading}>Rooms in Noida</p>
                    </a>
                    <a
                    href="https://findmyroom.in/room/noida/sector-1-noida-extension"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Sector- 1</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/noida/sector-15-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Sector- 15</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/noida/sector-22-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Sector- 22</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/noida/sector-55-and-56-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Sector- 55 and 56</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/noida/sector-47-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Sector- 47</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/noida/sector-137-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Sector-137</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/noida/sector-44-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Sector- 44</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/noida/sector-39-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Sector -39</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/noida/sector-19-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Sector-19</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/noida/sector-75-and-76-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Sector 75 & 76</p>
                  </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/room/ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Rooms in Ahmedabad
                      </p>
                    </a>

                    <a
                    href="https://findmyroom.in/room/ahmedabad/satellite-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Satellite</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/ahmedabad/sg-highway-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in SG Highway</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/ahmedabad/prahlad-nagar-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Prahlad Nagar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/ahmedabad/gota-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Gota</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/ahmedabad/bodak-dev-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Bodak Dev</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/ahmedabad/chandkheda-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Chandkheda</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/ahmedabad/thaltej-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Thaltej</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/ahmedabad/maninagar-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Maninagar</p>
                  </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a href="https://findmyroom.in/room/delhi" style={{ textDecoration: "none" }}>
                      <p className={classes.footerHeading}>Rooms in Delhi</p>
                    </a>
                    <a
                    href="https://findmyroom.in/room/delhi/hauz-khas-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Hauz Khas</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/delhi/rajouri-garden-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Rajouri Garden</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/delhi/vasant-kunj-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Vasant Kunj</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/delhi/lajpat-nagar-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Lajpat Nagar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/delhi/nehru-place-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Nehru Place</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/delhi/mayur-vihar-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Mayur Vihar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/delhi/karol-bagh-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Karol Bagh</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/delhi/dwarka-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Dwarka</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/delhi/south-extension-south-ex-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in South Extension</p>
                  </a>
                  <a
                    href="https://findmyroom.in/room/delhi/greater-kailash-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Room for Rent in Greater Kailash</p>
                  </a>
                  </Grid>
                </Grid>
              )}
              {this.props.searchRoomType === "pg" && (
                <Grid container spacing={3} className={classes.citiesContainer}>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a href="https://findmyroom.in/pg/bangalore" style={{ textDecoration: "none" }}>
                      <p className={classes.footerHeading}>PG in Bangalore</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/hsr-layout"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in HSR Layout</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/bellandur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Bellandur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/btm-layout"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in BTM Layout</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/koramangala"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Koramangala</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/manayata-tech-park-nagavara"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Manyata Tech Park</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/marathahalli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Marathahalli</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/jp-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in JP Nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/rt-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in RT Nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/kadugodi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Kadugodi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/indiranagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Indiranagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/yeshwantpur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Yeshwantpur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/kodihalli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Kodihalli</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/banashankari"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Banashankari</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/basavanagudi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Basavanagudi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/ulsoor"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Ulsoor</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/hebbal"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Hebbal</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/jayanagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Jayanagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/cv-raman-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        PG in CV Raman Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/itpl"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in ITPL</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/bangalore/sarjapur-road"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Sarjapur Road</p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a href="https://findmyroom.in/pg/pune" style={{ textDecoration: "none" }}>
                      <p className={classes.footerHeading}>PG in Pune</p>{" "}
                    </a>

                    <a href="https://findmyroom.in/pg/pune/wakad" style={{ textDecoration: "none" }}>
                      <p className={classes.footerLinks}>PG in Wakad</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/hinjewadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Hinjewadi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/kharadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Kharadi</p>
                    </a>
                    <a href="https://findmyroom.in/pg/pune/Baner-Gaon-Baner" style={{ textDecoration: "none" }}>
                      <p className={classes.footerLinks}>PG in Baner</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/kothrud"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Kothrud</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/karve-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Karve Nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/kharadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Kharadi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/vishal-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Vishal Nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/fursungi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Fursungi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/wadgaon-sheri"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Wadgaon Sheri</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/pune-nagar-road"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        PG in Pune Nagar Road
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/magarpatta-city"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        PG in Magarpatta City
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/koregaon-park"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Koregaon Park</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/mahalunge"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Mahalunge</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/hadapsar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Hadapsar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/talawade"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Talawade</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/pimple-saudagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        PG in Pimple Saudagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/vadgaon-budruk"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        PG in Vadgaon Budruk
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/pune/viman-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Viman Nagar</p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a href="https://findmyroom.in/pg/mumbai" style={{ textDecoration: "none" }}>
                      <p className={classes.footerHeading}>PG in Mumbai</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/jogeshwari-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        PG in Jogeshwari East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/chembur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Chembur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/ghatkopar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Ghatkopar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/kurla-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Kurla West</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/andheri-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Andheri East</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/bandra"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Bandra</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/malad-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Malad East</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/bhandup-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Bhandup West</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/santacruz-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        PG in Santacruz East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/ghatkopar-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        PG in Ghatkopar East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/bandra-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Bandra West</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/worli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Worli</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/tardeo"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Tardeo</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/navi-mumbai"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Navi Mumbai</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/goregaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Goregaon</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/mumbai/lokhandwala-complex"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        PG in Lokhandwala Complex
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a href="https://findmyroom.in/pg/hyderabad" style={{ textDecoration: "none" }}>
                      <p className={classes.footerHeading}>PG in Hyderabad</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/madhapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Madhapur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/moosapet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Moosapet</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/gachibowli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Gachibowli</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/begumpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Begumpet</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/miyapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Miyapur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/kukatpally"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Kukatpally</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/manikonda"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Manikonda</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/hafeezpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Hafeezpet</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/financial-district"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        PG in Financial District
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/hitech-city"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Hitech City</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/kphb"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in KPHB</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/kompally"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Kompally</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/kondapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Kondapur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/banjara-hills"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Banjara Hills</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/ameerpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Ameerpet</p>
                    </a>
                    <a
                      href="https://findmyroom.in/pg/hyderabad/somajiguda"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>PG in Somajiguda</p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a href="https://findmyroom.in/pg/gurgaon" style={{ textDecoration: "none" }}>
                      <p className={classes.footerHeading}>PG in Gurgaon</p>
                    </a>
                    <a
                    href="https://findmyroom.in/pg/gurgaon/civil-lanes-gurgaon"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Civil Lanes</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/gurgaon/patel-nagar-gurgaon"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in patel nagar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/gurgaon/sultanpur-gurgaon"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in sultanpur</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/gurgaon/dlf-city-gurgaon"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in DLF City</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/gurgaon/south-city-1-gurgaon"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in South City 1</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/gurgaon/sohna-road-gurgaon"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Sohna Road</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/gurgaon/palam-vihar-gurgaon"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Palam Vihar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/gurgaon/mg-road-gurgaon"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in MG Road</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/gurgaon/sushant-lok-1-gurgaon"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Sushant Lok 1</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/gurgaon/south-city-2-gurgaon"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in South City 2</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/gurgaon/nirvana-country-gurgaon"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Nirvana Country</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/gurgaon/golf-course-extension-gurgaon"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in 
                      Golf Course Extension
                    </p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/gurgaon/gurgaon-sector-82-gurgaon"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Gurgaon Sector 82</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/gurgaon/golf-course-road-gurgaon"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Golf Course Road</p>
                  </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a href="https://findmyroom.in/pg/noida" style={{ textDecoration: "none" }}>
                      <p className={classes.footerHeading}>PG in Noida</p>
                    </a>
                    <a
                    href="https://findmyroom.in/pg/noida/sector-1-noida-extension"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Sector- 1</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/noida/sector-15-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Sector- 15</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/noida/sector-22-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Sector- 22</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/noida/sector-55-and-56-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Sector- 55 and 56</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/noida/sector-47-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Sector- 47</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/noida/sector-137-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in  Sector-137</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/noida/sector-44-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Sector- 44</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/noida/sector-39-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Sector -39</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/noida/sector-19-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Sector-19</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/noida/sector-75-and-76-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Sector 75 & 76</p>
                  </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a href="https://findmyroom.in/pg/ahmedabad" style={{ textDecoration: "none" }}>
                      <p className={classes.footerHeading}>PG in Ahmedabad</p>
                    </a>
                    <a
                    href="https://findmyroom.in/pg/ahmedabad/satellite-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Satellite</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/ahmedabad/sg-highway-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in SG Highway</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/ahmedabad/prahlad-nagar-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Prahlad Nagar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/ahmedabad/gota-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Gota</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/ahmedabad/bodak-dev-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Bodak Dev</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/ahmedabad/chandkheda-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Chandkheda</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/ahmedabad/thaltej-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Thaltej</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/ahmedabad/maninagar-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Maninagar</p>
                  </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a href="https://findmyroom.in/pg/delhi" style={{ textDecoration: "none" }}>
                      <p className={classes.footerHeading}>PG in Delhi</p>
                    </a>
                    <a
                    href="https://findmyroom.in/pg/delhi/hauz-khas-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Hauz Khas</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/delhi/rajouri-garden-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Rajouri Garden</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/delhi/vasant-kunj-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Vasant Kunj</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/delhi/lajpat-nagar-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Lajpat Nagar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/delhi/nehru-place-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Nehru Place</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/delhi/mayur-vihar-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Mayur Vihar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/delhi/karol-bagh-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Karol Bagh</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/delhi/dwarka-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Dwarka</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/delhi/south-extension-south-ex-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in South Extension</p>
                  </a>
                  <a
                    href="https://findmyroom.in/pg/delhi/greater-kailash-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>PG in Greater Kailash</p>
                  </a>
                  </Grid>
                </Grid>
              )}
              {this.props.searchRoomType === "entire-house" && (
                <Grid container spacing={3} className={classes.citiesContainer}>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        House for Rent in Bangalore
                      </p>
                    </a>

                    <a
                      href="https://findmyroom.in/entire-house/bangalore/hsr-layout"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in HSR Layout
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/bellandur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Bellandur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/btm-layout"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in BTM Layout
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/koramangala"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Koramangala
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/manayata-tech-park-nagavara"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Manyata Tech Park
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/marathahalli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Marathahalli
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/jp-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in JP Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/rt-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in RT Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/kadugodi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Kadugodi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/indiranagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Indiranagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/yeshwantpur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Yeshwantpur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/kodihalli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Kodihalli
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/banashankari"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Banashankari
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/basavanagudi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Basavanagudi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/ulsoor"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Ulsoor
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/hebbal"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Hebbal
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/jayanagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Jayanagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/cv-raman-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in CV Raman Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/itpl"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in ITPL
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/bangalore/sarjapur-road"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Sarjapur Road
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/entire-house/pune"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        House for Rent in Pune
                      </p>
                    </a>

                    <a
                      href="https://findmyroom.in/entire-house/pune/wakad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Wakad
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/hinjewadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Hinjewadi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/kharadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Kharadi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/Baner-Gaon-Baner"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Baner
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/kothrud"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Kothrud
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/karve-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Karve Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/kharadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Kharadi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/vishal-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Vishal Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/fursungi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Fursungi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/wadgaon-sheri"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Wadgaon Sheri
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/pune-nagar-road"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Pune Nagar Road
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/magarpatta-city"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Magarpatta City
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/koregaon-park"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Koregaon Park
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/mahalunge"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Mahalunge
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/hadapsar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Hadapsar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/talawade"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Talawade
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/pimple-saudagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Pimple Saudagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/vadgaon-budruk"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Vadgaon Budruk
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/pune/viman-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Viman Nagar
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        House for Rent in Mumbai
                      </p>
                    </a>

                    <a
                      href="https://findmyroom.in/entire-house/mumbai/jogeshwari-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Jogeshwari East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai/chembur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Chembur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai/ghatkopar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Ghatkopar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai/kurla-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Kurla West
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai/andheri-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Andheri East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai/bandra"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Bandra
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai/malad-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Malad East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai/bhandup-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Bhandup West
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai/santacruz-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Santacruz East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai/ghatkopar-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Ghatkopar East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai/bandra-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Bandra West
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai/worli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Worli
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai/tardeo"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Tardeo
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai/navi-mumbai"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Navi Mumbai
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai/goregaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Goregaon
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/mumbai/lokhandwala-complex"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Lokhandwala Complex
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        House for Rent in Hyderabad
                      </p>
                    </a>

                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/madhapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Madhapur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/moosapet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Moosapet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/gachibowli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Gachibowli
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/begumpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Begumpet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/miyapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Miyapur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/kukatpally"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Kukatpally
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/manikonda"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Manikonda
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/hafeezpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Hafeezpet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/financial-district"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Financial District
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/hitech-city"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Hitech City
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/kphb"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in KPHB
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/kompally"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Kompally
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/kondapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Kondapur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/banjara-hills"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Banjara Hills
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/ameerpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Ameerpet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/hyderabad/somajiguda"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        House for Rent in Somajiguda
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/entire-house/gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        House for Rent in Gurgaon
                      </p>
                    </a>

                    <a href={`https://findmyroom.in/entire-house/gurgaon/civil-lanes-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>House for Rent in Civil Lanes</p>
        </a>
<a href={`https://findmyroom.in/entire-house/gurgaon/patel-nagar-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>House for Rent in patel nagar</p>
        </a>
<a href={`https://findmyroom.in/entire-house/gurgaon/sultanpur-gurgaon`}
          style={{ textDecoration: "none" }}>
<p className={classes.footerLinks}>House for Rent in sultanpur</p>
        </a>
<a href={`https://findmyroom.in/entire-house/gurgaon/dlf-city-gurgaon`}
          style={{ textDecoration: "none" }}>
             <p className={classes.footerLinks}>House for Rent in DLF City</p>
        </a>
<a href={`https://findmyroom.in/entire-house/gurgaon/south-city-1-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>House for Rent in South City 1</p>
        </a>
<a href={`https://findmyroom.in/entire-house/gurgaon/sohna-road-gurgaon`}
          style={{ textDecoration: "none" }}>
  <p className={classes.footerLinks}>House for Rent in Sohna Road</p>
        </a>
<a href={`https://findmyroom.in/entire-house/gurgaon/palam-vihar-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>House for Rent in Palam Vihar</p>
        </a>
<a href={`https://findmyroom.in/entire-house/gurgaon/mg-road-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>House for Rent in MG Road</p>
        </a>
<a href={`https://findmyroom.in/entire-house/gurgaon/sushant-lok-1-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>House for Rent in Sushant Lok 1</p>
        </a>
<a href={`https://findmyroom.in/entire-house/gurgaon/south-city-2-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>House for Rent in South City 2</p>
        </a>
<a href={`https://findmyroom.in/entire-house/gurgaon/nirvana-country-gurgaon`}
          style={{ textDecoration: "none" }}>
  <p className={classes.footerLinks}>House for Rent in Nirvana Country</p>
        </a>
<a href={`https://findmyroom.in/entire-house/gurgaon/golf-course-extension-gurgaon`}
          style={{ textDecoration: "none" }}>
     <p className={classes.footerLinks}>
            House for Rent in Golf Course Extension </p> </a>
<a href={`https://findmyroom.in/entire-house/gurgaon/gurgaon-sector-82-gurgaon`}
          style={{ textDecoration: "none" }}>   
          <p className={classes.footerLinks}>House for Rent in Gurgaon Sector 82</p>
        </a>
<a href={`https://findmyroom.in/entire-house/gurgaon/golf-course-road-gurgaon`}
          style={{ textDecoration: "none" }}>  
          <p className={classes.footerLinks}>House for Rent in Golf Course Road</p>
        </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/entire-house/noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        House for Rent in Noida
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/noida/sector-1-noida-extension"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Sector- 1</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/noida/sector-15-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Sector- 15</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/noida/sector-22-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Sector- 22</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/noida/sector-55-and-56-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Sector- 55 and 56</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/noida/sector-47-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Sector- 47</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/noida/sector-137-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in  Sector-137</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/noida/sector-44-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Sector- 44</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/noida/sector-39-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Sector -39</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/noida/sector-19-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Sector-19</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/noida/sector-75-and-76-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Sector 75 & 76</p>
                    </a>
                  </Grid>
              
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/entire-house/ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        House for Rent in Ahmedabad
                      </p>
                    </a>
                  <a
                      href="https://findmyroom.in/entire-house/ahmedabad/satellite-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Satellite</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/ahmedabad/sg-highway-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in SG Highway</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/ahmedabad/prahlad-nagar-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Prahlad Nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/ahmedabad/gota-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Gota</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/ahmedabad/bodak-dev-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Bodak Dev</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/ahmedabad/chandkheda-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Chandkheda</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/ahmedabad/thaltej-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Thaltej</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/ahmedabad/maninagar-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Maninagar</p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/entire-house/delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        House for Rent in Delhi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/delhi/hauz-khas-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Hauz Khas</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/delhi/rajouri-garden-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Rajouri Garden</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/delhi/vasant-kunj-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Vasant Kunj</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/delhi/lajpat-nagar-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Lajpat Nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/delhi/nehru-place-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Nehru Place</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/delhi/mayur-vihar-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Mayur Vihar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/delhi/karol-bagh-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Karol Bagh</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/delhi/dwarka-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Dwarka</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/delhi/south-extension-south-ex-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in South Extension</p>
                    </a>
                    <a
                      href="https://findmyroom.in/entire-house/delhi/greater-kailash-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>House for Rent in Greater Kailash</p>
                    </a>
                  </Grid>
                </Grid>
              )}
              {this.props.searchRoomType === "co-living" && (
                <Grid container spacing={3} className={classes.citiesContainer}>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/co-living/bangalore"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Coliving in Bangalore
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/hsr-layout"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in HSR Layout
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/bellandur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Bellandur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/btm-layout"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in BTM Layout
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/koramangala"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Koramangala
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/manayata-tech-park-nagavara"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Coliving in Manyata Tech Park</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/marathahalli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Marathahalli
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/jp-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in JP Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/rt-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in RT Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/kadugodi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Kadugodi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/indiranagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Indiranagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/yeshwantpur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Yeshwantpur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/kodihalli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Kodihalli
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/banashankari"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Banashankari
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/basavanagudi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Basavanagudi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/ulsoor"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Coliving in Ulsoor</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/hebbal"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Coliving in Hebbal</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/jayanagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Jayanagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/cv-raman-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in CV Raman Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/itpl"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Coliving in ITPL</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/bangalore/sarjapur-road"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Sarjapur Road
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/co-living/pune"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>Coliving in Pune</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/wakad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Coliving in Wakad</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/hinjewadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Hinjewadi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/kharadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Coliving in Kharadi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/Baner-Gaon-Baner"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Coliving in Baner</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/kothrud"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Coliving in Kothrud</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/karve-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Karve Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/kharadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Coliving in Kharadi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/vishal-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Vishal Nagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/fursungi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Fursungi
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/wadgaon-sheri"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Wadgaon Sheri
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/pune-nagar-road"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Pune Nagar Road
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/magarpatta-city"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Magarpatta City
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/koregaon-park"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Koregaon Park
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/mahalunge"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Mahalunge
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/hadapsar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Hadapsar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/talawade"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Talawade
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/pimple-saudagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Pimple Saudagar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/vadgaon-budruk"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Vadgaon Budruk
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/pune/viman-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Viman Nagar
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/co-living/mumbai"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Coliving in Mumbai
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/jogeshwari-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Jogeshwari East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/chembur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Coliving in Chembur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/ghatkopar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Ghatkopar
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/kurla-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Kurla West
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/andheri-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Andheri East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/bandra"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Coliving in Bandra</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/malad-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Malad East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/bhandup-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Bhandup West
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/santacruz-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Santacruz East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/ghatkopar-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Ghatkopar East
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/bandra-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Bandra West
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/worli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Coliving in Worli</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/tardeo"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Coliving in Tardeo</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/navi-mumbai"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Navi Mumbai
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/goregaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Goregaon
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/mumbai/lokhandwala-complex"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Lokhandwala Complex
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Coliving in Hyderabad
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/madhapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Madhapur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/moosapet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Moosapet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/gachibowli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Gachibowli
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/begumpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Begumpet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/miyapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Coliving in Miyapur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/kukatpally"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Kukatpally
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/manikonda"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Manikonda
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/hafeezpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Hafeezpet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/financial-district"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Financial District
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/hitech-city"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Hitech City
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/kphb"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Coliving in KPHB</p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/kompally"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Kompally
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/kondapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Kondapur
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/banjara-hills"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Banjara Hills
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/ameerpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Ameerpet
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/hyderabad/somajiguda"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Somajiguda
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/co-living/gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Coliving in Gurgaon
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/co-living/gurgaon/civil-lanes-gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Coliving in Civil Lanes
                      </p>
                    </a>
                    <a href={`https://findmyroom.in/co-living/gurgaon/civil-lanes-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Coliving in Civil Lanes</p>
        </a>
<a href={`https://findmyroom.in/co-living/gurgaon/patel-nagar-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>Coliving in patel nagar</p>
        </a>
<a href={`https://findmyroom.in/co-living/gurgaon/sultanpur-gurgaon`}
          style={{ textDecoration: "none" }}>
<p className={classes.footerLinks}>Coliving in sultanpur</p>
        </a>
<a href={`https://findmyroom.in/co-living/gurgaon/dlf-city-gurgaon`}
          style={{ textDecoration: "none" }}>
             <p className={classes.footerLinks}>Coliving in DLF City</p>
        </a>
<a href={`https://findmyroom.in/co-living/gurgaon/south-city-1-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>Coliving in South City 1</p>
        </a>
<a href={`https://findmyroom.in/co-living/gurgaon/sohna-road-gurgaon`}
          style={{ textDecoration: "none" }}>
  <p className={classes.footerLinks}>Coliving in Sohna Road</p>
        </a>
<a href={`https://findmyroom.in/co-living/gurgaon/palam-vihar-gurgaon`}
          style={{ textDecoration: "none" }}>
 <p className={classes.footerLinks}>Coliving in Palam Vihar</p>
        </a>
<a href={`https://findmyroom.in/co-living/gurgaon/mg-road-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Coliving in MG Road</p>
        </a>
<a href={`https://findmyroom.in/co-living/gurgaon/sushant-lok-1-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Coliving in Sushant Lok 1</p>
        </a>
<a href={`https://findmyroom.in/co-living/gurgaon/south-city-2-gurgaon`}
          style={{ textDecoration: "none" }}>
   <p className={classes.footerLinks}>Coliving in South City 2</p>
        </a>
<a href={`https://findmyroom.in/co-living/gurgaon/nirvana-country-gurgaon`}
          style={{ textDecoration: "none" }}>
  <p className={classes.footerLinks}>Coliving in Nirvana Country</p>
        </a>
<a href={`https://findmyroom.in/co-living/gurgaon/golf-course-extension-gurgaon`}
          style={{ textDecoration: "none" }}>
     <p className={classes.footerLinks}>
            Coliving in Golf Course Extension </p> </a>
<a href={`https://findmyroom.in/co-living/gurgaon/gurgaon-sector-82-gurgaon`}
          style={{ textDecoration: "none" }}>   
          <p className={classes.footerLinks}>Coliving in Gurgaon Sector 82</p>
        </a>
<a href={`https://findmyroom.in/co-living/gurgaon/golf-course-road-gurgaon`}
          style={{ textDecoration: "none" }}>  
          <p className={classes.footerLinks}>Coliving in Golf Course Road</p>
        </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/co-living/noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>Coliving in Noida</p>
                    </a>
                    <a
                    href="https://findmyroom.in/co-living/noida/sector-1-noida-extension"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Sector- 1</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/noida/sector-15-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Sector- 15</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/noida/sector-22-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Sector- 22</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/noida/sector-55-and-56-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Sector- 55 and 56</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/noida/sector-47-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Sector- 47</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/noida/sector-137-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in  Sector-137</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/noida/sector-44-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Sector- 44</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/noida/sector-39-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Sector -39</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/noida/sector-19-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Sector-19</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/noida/sector-75-and-76-noida"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Sector 75 & 76</p>
                  </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/co-living/ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>
                        Coliving in Ahmedabad
                      </p>
                    </a>
                    <a
                    href="https://findmyroom.in/co-living/ahmedabad/satellite-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Satellite</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/ahmedabad/sg-highway-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in SG Highway</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/ahmedabad/prahlad-nagar-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Prahlad Nagar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/ahmedabad/gota-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Gota</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/ahmedabad/bodak-dev-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Bodak Dev</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/ahmedabad/chandkheda-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Chandkheda</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/ahmedabad/thaltej-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Thaltej</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/ahmedabad/maninagar-ahmedabad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Maninagar</p>
                  </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/co-living/delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>Coliving in Delhi</p>
                    </a>
                    <a
                    href="https://findmyroom.in/co-living/delhi/hauz-khas-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Hauz Khas</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/delhi/rajouri-garden-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Rajouri Garden</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/delhi/vasant-kunj-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Vasant Kunj</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/delhi/lajpat-nagar-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Lajpat Nagar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/delhi/nehru-place-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Nehru Place</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/delhi/mayur-vihar-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Mayur Vihar</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/delhi/karol-bagh-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Karol Bagh</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/delhi/dwarka-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Dwarka</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/delhi/south-extension-south-ex-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in South Extension</p>
                  </a>
                  <a
                    href="https://findmyroom.in/co-living/delhi/greater-kailash-delhi"
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerLinks}>Coliving in Greater Kailash</p>
                  </a>
                  </Grid>
                </Grid>
              )}

              {/* dont change */}
              {!this.props.searchRoomType && (
                <Grid container spacing={3} className={classes.citiesContainer}>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/properties/bangalore"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>Bangalore</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/hsr-layout"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>HSR Layout</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/bellandur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Bellandur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/btm-layout"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>BTM Layout</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/koramangala"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Koramangala</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/manayata-tech-park-nagavara"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Manyata Tech Park</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/marathahalli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Marathahalli</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/jp-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>JP Nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/rt-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>RT Nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/kadugodi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Kadugodi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/indiranagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Indiranagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/yeshwantpur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Yeshwantpur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/kodihalli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Kodihalli</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/banashankari"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Banashankari</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/basavanagudi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Basavanagudi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/ulsoor"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Ulsoor</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/hebbal"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Hebbal</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/jayanagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Jayanagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/cv-raman-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>CV Raman Nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/itpl"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>ITPL</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/sarjapur-road"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Sarjapur Road</p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/properties/pune"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>Pune</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/wakad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Wakad</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/hinjewadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Hinjewadi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/kharadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Kharadi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/Baner-Gaon-Baner"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Baner</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/kothrud"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Kothrud</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/karve-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Karve Nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/kharadi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Kharadi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/vishal-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Vishal Nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/fursungi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Fursungi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/wadgaon-sheri"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Wadgaon Sheri</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/pune-nagar-road"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Pune Nagar Road</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/magarpatta-city"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Magarpatta City</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/koregaon-park"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Koregaon Park</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/mahalunge"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Mahalunge</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/hadapsar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Hadapsar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/talawade"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Talawade</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/pimple-saudagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Pimple Saudagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/vadgaon-budruk"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Vadgaon Budruk</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/viman-nagar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Viman Nagar</p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/properties/mumbai"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>Mumbai</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/jogeshwari-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Jogeshwari East</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/chembur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Chembur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/ghatkopar"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Ghatkopar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/kurla-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Kurla West</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/andheri-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Andheri East</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/bandra"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Bandra</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/malad-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Malad East</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/bhandup-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Bhandup West</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/santacruz-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Santacruz East</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/ghatkopar-east"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Ghatkopar East</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/bandra-west"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Bandra West</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/worli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Worli</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/tardeo"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Tardeo</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/navi-mumbai"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Navi Mumbai</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/goregaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Goregaon</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/lokhandwala-complex"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Lokhandwala Complex</p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/properties/hyderabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>Hyderabad</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/madhapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Madhapur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/moosapet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Moosapet</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/gachibowli"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Gachibowli</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/begumpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Begumpet</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/miyapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Miyapur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/kukatpally"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Kukatpally</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/manikonda"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Manikonda</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/hafeezpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Hafeezpet</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/financial-district"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Financial District</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/hitech-city"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Hitech City</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/kphb"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>KPHB</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/kompally"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Kompally</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/kondapur"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Kondapur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/banjara-hills"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Banjara Hills</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/ameerpet"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Ameerpet</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/somajiguda"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Somajiguda</p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/properties/gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>Gurgaon</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/civil-lanes-gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Civil Lanes</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/patel-nagar-gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>patel nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/sultanpur-gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>sultanpur</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/dlf-city-gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>DLF City</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/south-city-1-gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>South City 1</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/sohna-road-gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Sohna Road</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/palam-vihar-gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Palam Vihar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/mg-road-gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>MG Road</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/sushant-lok-1-gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Sushant Lok 1</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/south-city-2-gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>South City 2</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/nirvana-country-gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Nirvana Country</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/golf-course-extension-gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>
                        Golf Course Extension
                      </p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/gurgaon-sector-82-gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Gurgaon Sector 82</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/golf-course-road-gurgaon"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Golf Course Road</p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/properties/noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>Noida</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/sector-1-noida-extension"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Sector- 1</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/sector-15-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Sector- 15</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/sector-22-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Sector- 22</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/sector-55-and-56-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Sector- 55 and 56</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/sector-47-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Sector- 47</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/sector-137-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}> Sector-137</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/sector-44-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Sector- 44</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/sector-39-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Sector -39</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/sector-19-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Sector-19</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/sector75-and-76-noida"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Sector 75 & 76</p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/properties/ahmedabad "
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>Ahmedabad</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/satellite-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Satellite</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/sg-highway-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>SG Highway</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/prahlad-nagar-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Prahlad Nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/gota-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Gota</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/bodak-dev-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Bodak Dev</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/chandkheda-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Chandkheda</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/thaltej-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Thaltej</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/maninagar-ahmedabad"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Maninagar</p>
                    </a>
                  </Grid>
                  <Grid item sm={3} className={classes.footerDiv}>
                    <a
                      href="https://findmyroom.in/properties/delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerHeading}>Delhi</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/hauz-khas-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Hauz Khas</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/rajouri-garden-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Rajouri Garden</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/vasant-kunj-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Vasant Kunj</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/lajpat-nagar-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Lajpat Nagar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/nehru-place-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Nehru Place</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/mayur-vihar-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Mayur Vihar</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/karol-bagh-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Karol Bagh</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/dwarka-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Dwarka</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/south-extension-south-ex-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>South Extension</p>
                    </a>
                    <a
                      href="https://findmyroom.in/properties/greater-kailash-delhi"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={classes.footerLinks}>Greater Kailash</p>
                    </a>
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <>
              {this.props.currentCity === "Bengaluru" && (
                <>
                  <p className={classes.footerHeading1}>
                    Search in Top Localities
                  </p>
                  <Grid
                    container
                    spacing={3}
                    className={classes.citiesContainer}
                  >
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Roommates</p>
                      {this.getBangaloreLinks("roommate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Flatmates</p>
                      {this.getBangaloreLinks("flatmate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Rooms</p>
                      {this.getBangaloreLinks("room", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>PG</p>
                      {this.getBangaloreLinks("pg", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Entire Houses</p>
                      {this.getBangaloreLinks("entire-house", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Co-Living</p>
                      {this.getBangaloreLinks("co-living", classes)}
                    </Grid>
                  </Grid>
                </>
              )}
              {this.props.currentCity === "Pune" && (
                <>
                  <p className={classes.footerHeading1}>
                    Search in Top Localities
                  </p>
                  <Grid
                    container
                    spacing={3}
                    className={classes.citiesContainer}
                  >
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Roommates</p>
                      {this.getPuneLinks("roommate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Flatmates</p>
                      {this.getPuneLinks("flatmate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Rooms</p>
                      {this.getPuneLinks("room", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>PG</p>
                      {this.getPuneLinks("pg", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Entire Houses</p>
                      {this.getPuneLinks("entire-house", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Co-Living</p>
                      {this.getPuneLinks("co-living", classes)}
                    </Grid>
                  </Grid>
                </>
              )}
              {this.props.currentCity === "Hyderabad" && (
                <>
                  <p className={classes.footerHeading1}>
                    Search in Top Localities
                  </p>
                  <Grid
                    container
                    spacing={3}
                    className={classes.citiesContainer}
                  >
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Roommates</p>
                      {this.getHyderabadLinks("roommate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Flatmates</p>
                      {this.getHyderabadLinks("flatmate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Rooms</p>
                      {this.getHyderabadLinks("room", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>PG</p>
                      {this.getHyderabadLinks("pg", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Entire Houses</p>
                      {this.getHyderabadLinks("entire-house", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Co-Living</p>
                      {this.getHyderabadLinks("co-living", classes)}
                    </Grid>
                  </Grid>
                </>
              )}
              {this.props.currentCity === "Mumbai" && (
                <>
                  <p className={classes.footerHeading1}>
                    Search in Top Localities
                  </p>
                  <Grid
                    container
                    spacing={3}
                    className={classes.citiesContainer}
                  >
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Roommates</p>
                      {this.getMumbaiLinks("roommate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Flatmates</p>
                      {this.getMumbaiLinks("flatmate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Rooms</p>
                      {this.getMumbaiLinks("room", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>PG</p>
                      {this.getMumbaiLinks("pg", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Entire Houses</p>
                      {this.getMumbaiLinks("entire-house", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Co-Living</p>
                      {this.getMumbaiLinks("co-living", classes)}
                    </Grid>
                  </Grid>
                </>
              )}
              {this.props.currentCity === "Gurgaon" && (
                <>
                  <p className={classes.footerHeading1}>
                    Search in Top Localities
                  </p>
                  <Grid
                    container
                    spacing={3}
                    className={classes.citiesContainer}
                  >
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Roommates</p>
                      {this.getGurgaonLinks("roommate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Flatmates</p>
                      {this.getGurgaonLinks("flatmate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Rooms</p>
                      {this.getGurgaonLinks("room", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>PG</p>
                      {this.getGurgaonLinks("pg", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Entire Houses</p>
                      {this.getGurgaonLinks("entire-house", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Co-Living</p>
                      {this.getGurgaonLinks("co-living", classes)}
                    </Grid>
                  </Grid>
                </>
              )}
              {this.props.currentCity === "Noida" && (
                <>
                  <p className={classes.footerHeading1}>
                    Search in Top Localities
                  </p>
                  <Grid
                    container
                    spacing={3}
                    className={classes.citiesContainer}
                  >
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Roommates</p>
                      {this.getNoidaLinks("roommate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Flatmates</p>
                      {this.getNoidaLinks("flatmate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Rooms</p>
                      {this.getNoidaLinks("room", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>PG</p>
                      {this.getNoidaLinks("pg", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Entire Houses</p>
                      {this.getNoidaLinks("entire-house", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Co-Living</p>
                      {this.getNoidaLinks("co-living", classes)}
                    </Grid>
                  </Grid>
                </>
              )}
              {this.props.currentCity === "Ahmedabad" && (
                <>
                  <p className={classes.footerHeading1}>
                    Search in Top Localities
                  </p>
                  <Grid
                    container
                    spacing={3}
                    className={classes.citiesContainer}
                  >
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Roommates</p>
                      {this.getAhmedabadLinks("roommate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Flatmates</p>
                      {this.getAhmedabadLinks("flatmate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Rooms</p>
                      {this.getAhmedabadLinks("room", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>PG</p>
                      {this.getAhmedabadLinks("pg", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Entire Houses</p>
                      {this.getAhmedabadLinks("entire-house", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Co-Living</p>
                      {this.getAhmedabadLinks("co-living", classes)}
                    </Grid>
                  </Grid>
                </>
              )}
              {this.props.currentCity === "Delhi" && (
                <>
                  <p className={classes.footerHeading1}>
                    Search in Top Localities
                  </p>
                  <Grid
                    container
                    spacing={3}
                    className={classes.citiesContainer}
                  >
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Roommates</p>
                      {this.getDelhiLinks("roommate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Flatmates</p>
                      {this.getDelhiLinks("flatmate", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Rooms</p>
                      {this.getDelhiLinks("room", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>PG</p>
                      {this.getDelhiLinks("pg", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Entire Houses</p>
                      {this.getDelhiLinks("entire-house", classes)}
                    </Grid>
                    <Grid item sm={3} className={classes.footerDiv}>
                      <p className={classes.footerHeading}>Co-Living</p>
                      {this.getDelhiLinks("co-living", classes)}
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </section>
        <section className={classes.footer}>
          <Grid container spacing={3}>
            <Grid item xs={10} sm={3} md={3} className={classes.footerDiv}>
              <p className={classes.footerHeading}>TOP CITIES</p>
              <a
                href="https://findmyroom.in/properties/bangalore"
                style={{ textDecoration: "none" }}
              >
                <p className={classes.footerLinks}>Bangalore</p>
              </a>
              <a href="https://findmyroom.in/properties/pune" style={{ textDecoration: "none" }}>
                <p className={classes.footerLinks}>Pune</p>
              </a>
              <a href="https://findmyroom.in/properties/mumbai" style={{ textDecoration: "none" }}>
                <p className={classes.footerLinks}>Mumbai</p>
              </a>
              <a
                href="https://findmyroom.in/properties/hyderabad"
                style={{ textDecoration: "none" }}
              >
                <p className={classes.footerLinks}>Hyderabad</p>
              </a>
              <a
                href="https://findmyroom.in/properties/ahmedabad"
                style={{ textDecoration: "none" }}
              >
                <p className={classes.footerLinks}>Ahmedabad</p>
              </a>
              <a href="https://findmyroom.in/properties/delhi" style={{ textDecoration: "none" }}>
                <p className={classes.footerLinks}>Delhi</p>
              </a>
              <a href="https://findmyroom.in/properties/noida" style={{ textDecoration: "none" }}>
                <p className={classes.footerLinks}>Noida</p>
              </a>
              <a href="https://findmyroom.in/properties/gurgaon" style={{ textDecoration: "none" }}>
                <p className={classes.footerLinks}>Gurgaon</p>
              </a>
            </Grid>
            <Grid item xs={10} sm={3} md={3} className={classes.footerDiv}>
              <p className={classes.footerHeading}>COMMUNITIES</p>
              <a
                href="https://www.facebook.com/FindMyRoomBangalore/"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <p className={classes.footerLinks}>
                  Flat and Flatmates Bangalore
                </p>
              </a>
              <a
                href="https://www.facebook.com/FindMyRoomPune/"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <p className={classes.footerLinks}>Flat and Flatmates Pune</p>
              </a>
              <a
                href="https://www.facebook.com/FindMyRoomMumbai/"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <p className={classes.footerLinks}>Flat and Flatmates Mumbai</p>
              </a>
              <a
                href="https://www.facebook.com/FindMyRoomHyderabad/"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <p className={classes.footerLinks}>
                  Flat and Flatmates Hyderabad
                </p>
              </a>
              <a
                href="https://www.facebook.com/FindMyRoomAhmedabad/"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <p className={classes.footerLinks}>
                  Flat and Flatmates Ahmedabad
                </p>
              </a>
              <a
                href="https://www.facebook.com/FindMyRoomDelhi/"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <p className={classes.footerLinks}>Flat and Flatmates Delhi</p>
              </a>
              <a
                href="https://www.facebook.com/findmyroomnoida"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <p className={classes.footerLinks}>Flat and Flatmates Noida</p>
              </a>
              <a
                href="https://www.facebook.com/Flat-and-Flatmates-Gurgaon-109178054645359"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <p className={classes.footerLinks}>
                  Flat and Flatmates Gurgaon
                </p>
              </a>
            </Grid>
            <Grid item xs={10} sm={3} md={3} className={classes.footerDiv}>
              <p className={classes.footerHeading}>SERVICES</p>
              <a href="https://findmyroom.in/post/rent" style={{ textDecoration: "none" }}>
                <p className={classes.footerLinks}>Find a Roommate</p>{" "}
              </a>
              <a href="https://findmyroom.in/post/rent" style={{ textDecoration: "none" }}>
                <p className={classes.footerLinks}>Advertise a room</p>{" "}
              </a>
              <a href="https://findmyroom.in/post/requirement" style={{ textDecoration: "none" }}>
                {" "}
                <p className={classes.footerLinks}>Post a room wanted ad</p>
              </a>
              <a href="https://findmyroom.in/post/rent" style={{ textDecoration: "none" }}>
                {" "}
                <p className={classes.footerLinks}>
                  Advertise a full apartment
                </p>
              </a>
              <a href="https://findmyroom.in/flat-and-flatmates" style={{ textDecoration: "none" }}>
                {" "}
                <p className={classes.footerLinks}>
                  Flat and Flatmates Communities
                </p>
              </a>
            </Grid>
            <Grid item xs={10} sm={3} md={3} className={classes.footerDiv}>
              <p className={classes.footerHeading}>COMPANY</p>
              <a href="https://findmyroom.in/contact" style={{ textDecoration: "none" }}>
                <p className={classes.footerLinks}>Contact Us</p>
              </a>
              {/* <a
                href="https://findmyroomhelp.zendesk.com/hc/en-us"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <p className={classes.footerLinks}>FAQ</p>
              </a> */}
              <a href="https://findmyroom.in/terms-of-use" style={{ textDecoration: "none" }}>
                <p className={classes.footerLinks}>Terms of Use</p>
              </a>
              <a href="https://findmyroom.in/privacy-policy" style={{ textDecoration: "none" }}>
                {" "}
                <p className={classes.footerLinks}>Privacy Policy</p>
              </a>
              <a href="https://findmyroom.in/refund-policy" style={{ textDecoration: "none" }}>
                {" "}
                <p className={classes.footerLinks}>Refund Policy</p>
              </a>
              <a href="https://findmyroom.in/sitemap" style={{ textDecoration: "none" }}>
                {" "}
                <p className={classes.footerLinks}>Sitemap</p>
              </a>
            </Grid>
          </Grid>
          <section className={classes.footerContent}>
            <p className={classes.footerHeading}>FOLLOW US ON</p>
          </section>
          <section className={classes.socialIcons}>
            <div className={classes.socialIcon}>
              <a
                href="https://www.facebook.com/findmyroompage"
                style={{ color: "white" }}
              >
                {" "}
                <FacebookIcon />{" "}
              </a>
            </div>
            <div className={classes.socialIcon}>
              <a
                href="https://www.instagram.com/findmyroom/"
                style={{ color: "white" }}
              >
                {" "}
                <InstagramIcon />
              </a>
            </div>
            <div className={classes.socialIcon}>
              <a
                href="https://twitter.com/findmyroom1"
                style={{ color: "white" }}
              >
                {" "}
                <TwitterIcon />{" "}
              </a>
            </div>
            <div className={classes.socialIcon}>
              <a
                href="https://www.linkedin.com/company/findmyroom"
                style={{ color: "white" }}
              >
                {" "}
                <LinkedInIcon />{" "}
              </a>
            </div>
          </section>
          <section className={classes.footerContents}>
            <p className={classes.footerLinks}>
              FindMyRoom ⓒ 2023. All rights reserved
            </p>
          </section>
          {/* {this.state.open && <Chatbot />} */}
        </section>
        <style jsx global>{`
          html,
          body {
            padding: 0;
            margin: 0;
            font-family: Poppins !important;
          }

          * {
            box-sizing: border-box;
          }
        `}</style>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Layout);
